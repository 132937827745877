import {useState, useEffect, useRef} from 'react'
import Prompt from "../_components/SlidePrompt";
import { SlideProps } from "../../../../../types/taskTypes";
import { useAppDispatch, useAppSelector } from "../../../../../store/store";
import Loader from "../../../../utils/Loader";
import { setActiveSlide, setIsSlideDone, setTypedAnswer } from "../../../../../store/features/taskPlayerSlice";
import SlideImage from '../_components/SlideImage';
import { matchStringCase } from '../../player_services/matchStringCase';
import { API_URL } from '../../../../../Constants';
import Skipper from '../_components/Skipper';
import { checkAnswer } from '../../player_services/checkAnswer';

export default function SlideReadingOneWord({ data }: { data: SlideProps }) {
  const typedAnswer = useAppSelector((state) => state.taskPlayer.typedAnswer)
  const correctAnswer = data.correct_answer;
  const activeSlideIndex = useAppSelector((state) => state.taskPlayer.activeSlideIndex)
  const taskImgUrl = useAppSelector((state) => state.taskPlayer.activeTask!.thumbnail_url)
  const [isDone, setIsDone] = useState(false)
  const dispatch = useAppDispatch();
  const answerInputRef = useRef(null);

  useEffect(() => {
    setIsDone(false)
    if(checkAnswer(correctAnswer, typedAnswer)){
      dispatch(setIsSlideDone(true))
      setIsDone(true)
      setTimeout(() => {
        dispatch(setActiveSlide(activeSlideIndex+1))
      }, 1500);
    }
  }, [typedAnswer])

  useEffect(() => {
    setIsDone(false)
    //@ts-ignore
    answerInputRef.current.focus()
  }, [activeSlideIndex])
  
  
  const handleTypedAnswer = (e: React.ChangeEvent<HTMLInputElement>) => {
    let caseMatchingString = matchStringCase(correctAnswer, e.target.value);
    dispatch(setTypedAnswer(caseMatchingString));
  }


  if (data === undefined) {
    return <Loader />;
  }

  return (
    <div className="slide-one-word">
      <div className="p-3 grid justify-center">
        <div className="flex justify-center w-[220px] dark:bg-black rounded-md overflow-hidden">
          <SlideImage img_url={taskImgUrl} customClasses={'h-[100px] rounded-md'}/>
        </div>
      </div>
      <div className={`${isDone ? "fade-out" : "fade-in-animation"}`}>
        <div className="px-4 pb-2 text-neutral-600 dark:text-white">
          <Prompt rawContent={data?.prompt} />
        </div>
          <div className="grid relative justify-center">
            <input
              type="text"
              className="form-input p-1 text-center text-[20px] max-w-[140px]"
              autoFocus
              onChange={(e) => handleTypedAnswer(e)}
              value={typedAnswer}
              ref={answerInputRef}
            />
            <Skipper customStyles='right-2 -bottom-2 left-auto !pt-0'/>
          </div>
      </div>
    </div>
  );
}
