import React from 'react'
import { useAppSelector } from '../../../../store/store'
import { TaskProps } from '../../../../types/taskTypes'
import { API_URL } from '../../../../Constants'
import LazyImage from '../../../../components/task/player/slide/_components/LazyImage'
import TaskType from '../../../../components/task/card/TaskType'
import { Navigate, useNavigate } from 'react-router-dom'
import Loader from '../../../../components/utils/Loader'

export default function SearchResults() {

const navigate = useNavigate();

const results = useAppSelector((state) => state.feed.searchResults)
const isSearching = useAppSelector((state) => state.feed.isSearching)

  return (
    <div className="search-results menu-fade-in fixed bottom-[140px] left-0 w-full bg-primary-light max-h-[40vh] pt-4 px-4 overflow-y-scroll border-y-2  border-white">
        {isSearching && 
            <div className="text-center pb-8 pt-2">
                <Loader customClasses='!text-white !h-8 !w-8'/>
            </div>
        }

        {!isSearching && results && results.length == 0 && 
            <p className="text-white text-center pb-4">No results were found</p>
        }

        {results && results.length > 0 && results.map((task: TaskProps) => (
            <div
                key={task.id}
                onClick={() => {navigate(task.slug)}} 
                className="grid grid-cols-12 relative bg-primary mb-4 h-[75px] rounded-md overflow-hidden text-white lg:mx-48 shadow-lg ">
                <div className="col-span-3 grid relative overflow-hidden items-center">
                    <div className="task-card-type-icon !w-8 !h-8 absolute top-0 left-0 z-[110] !pl-[3px] !pt-[6px]">
                        <TaskType type={task.type} />
                    </div>
                    <LazyImage 
                        imgSrc={task.thumbnail_url} 
                        customClasses="rounded-md absolute top-0 left-0 lg:left-0 h-full max-w-[220%]"
                    />
                </div>
                <div className="col-span-9 p-2 grid items-start overflow-y-scroll lg:overflow-auto">
                    <h4 className="text-sm  font-bold">{task.title}</h4>
                    <p className="text-sm text-white/70">{task.format?.instructions}</p>
                </div>
            </div>
        ))}
    </div>
  )
}
