import {Dispatch, SetStateAction, useState} from 'react'
import Modal from 'react-modal';
import CloseIcon from '@mui/icons-material/Close';
import { useMutation } from "react-query";
import { updateAvatar } from "../../../api/user";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { updateStoredAvatar } from "../../../store/features/authSlice";
import Avatar from "@mui/material/Avatar";

const availableIcons = [
  "icons8-aircraft-100.png",
  "icons8-alps-100.png",
  "icons8-asia-100.png",
  "icons8-bart-simpson-100.png",
  "icons8-basketball-100.png",
  "icons8-bass-drum-100.png",
  "icons8-batman-100.png",
  "icons8-bear-100.png",
  "icons8-beast-100.png",
  "icons8-bee-100.png",
  "icons8-bird-100.png",
  "icons8-black-cat-100.png",
  "icons8-black-jaguar-100.png",
  "icons8-black-panther-mask-100.png",
  "icons8-bulldozer-100.png",
  "icons8-butterfly-100.png",
  "icons8-cactus-100.png",
  "icons8-captain-america-100.png",
  "icons8-cat-100.png",
  "icons8-clew-100.png",
  "icons8-close-up-100.png",
  "icons8-clown-fish-100.png",
  "icons8-corgi-100.png",
  "icons8-cow-100.png",
  "icons8-crab-100.png",
  "icons8-cyclops-marvel-100.png",
  "icons8-deer-100.png",
  "icons8-dinosaur-100.png",
  "icons8-dog-100.png",
  "icons8-dolphin-100.png",
  "icons8-dragon-100.png",
  "icons8-duck-100.png",
  "icons8-elephant-100.png",
  "icons8-european-dragon-100.png",
  "icons8-falcon-100.png",
  "icons8-fire-truck-100.png",
  "icons8-flower-100-1.png",
  "icons8-flower-100-2.png",
  "icons8-flower-100-3.png",
  "icons8-flower-100-4.png",
  "icons8-flower-100-5.png",
  "icons8-flower-100-6.png",
  "icons8-flower-100-7.png",
  "icons8-flower-100.png",
  "icons8-forest-100.png",
  "icons8-frog-100.png",
  "icons8-full-moon-100.png",
  "icons8-funny-100.png",
  "icons8-gambit-100.png",
  "icons8-genie-100.png",
  "icons8-grasshopper-100.png",
  "icons8-guitar-100.png",
  "icons8-harry-potter-100-1.png",
  "icons8-harry-potter-100.png",
  "icons8-helicopter-100.png",
  "icons8-hello-kitty-100.png",
  "icons8-homer-simpson-100.png",
  "icons8-horse-100.png",
  "icons8-hummingbird-100.png",
  "icons8-iceberg-100.png",
  "icons8-iron-man-100.png",
  "icons8-island-on-water-100.png",
  "icons8-jersey-100.png",
  "icons8-kawaii-dinosaur-100.png",
  "icons8-lamb-100.png",
  "icons8-leopard-100.png",
  "icons8-lion-100.png",
  "icons8-love-100.png",
  "icons8-magneto-100.png",
  "icons8-minecraft-pickaxe-100.png",
  "icons8-minecraft-sword-100.png",
  "icons8-musical-100.png",
  "icons8-musical-notes-100.png",
  "icons8-naruto-100.png",
  "icons8-national-park-100.png",
  "icons8-neo-100.png",
  "icons8-ninja-turtle-100.png",
  "icons8-octopus-100.png",
  "icons8-one-ring-100.png",
  "icons8-owl-100.png",
  "icons8-panda-100.png",
  "icons8-piano-100.png",
  "icons8-planet-100.png",
  "icons8-pokeball-100.png",
  "icons8-police-car-100.png",
  "icons8-popcorn-100.png",
  "icons8-r2-d2-100.png",
  "icons8-rabbit-100.png",
  "icons8-ring-100.png",
  "icons8-rock-music-100.png",
  "icons8-romantic-movies-100.png",
  "icons8-rocket-100.png",
  "icons8-sailboat-100.png",
  "icons8-seed-of-life-100.png",
  "icons8-shark-100.png",
  "icons8-soil-100.png",
  "icons8-son-goku-100.png",
  "icons8-sonic-the-hedgehog-100.png",
  "icons8-spider-man-head-100.png",
  "icons8-sport-100.png",
  "icons8-stich-100.png",
  "icons8-storm-marvel-100.png",
  "icons8-stormtrooper-100.png",
  "icons8-super-mario-100.png",
  "icons8-superman-100.png",
  "icons8-swimming-cap-100.png",
  "icons8-sword-100.png",
  "icons8-the-flash-sign-100.png",
  "icons8-thor-100.png",
  "icons8-three-leaf-clover-100.png",
  "icons8-tropics-100.png",
  "icons8-turtle-100.png",
  "icons8-unicorn-100.png",
  "icons8-viking-ship-100.png",
  "icons8-violin-100.png",
  "icons8-whale-100.png",
  "icons8-wolf-100.png",
  "icons8-wolverine-100.png",
  "icons8-wonder-woman-100.png",
  "icons8-woody-woodpecker-100.png",
]

interface Props{
  modalIsOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
}

export default function AvatarModal(props:Props) {

  const dispatch = useAppDispatch();
  const userAvatar = useAppSelector((state) => state.auth.user?.avatar_file_name)

  const [selectedAvatar, setSelectedAvatar] = useState(userAvatar)

  const {mutate:update, isLoading} = useMutation(updateAvatar);
  

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    update({avatar_file_name: selectedAvatar}, { onSuccess: (resp) => {
      dispatch(updateStoredAvatar(resp));
      props.setIsModalOpen(false);
    }});
  };

  return (
    <Modal 
      isOpen={props.modalIsOpen}
      className="modal-setting-avatar">
        <h2 className="text-center bg-primary text-white text-2xl py-4 font-bricolage">Choose an avatar</h2>

        <div className="grid grid-cols-12 gap-2 max-h-[320px] overflow-y-scroll p-2 border-2 border-neutral-200 mb-8">
          {availableIcons.map((iconName) => (
            <div className="col-span-3" key={iconName} 
              onClick={() => setSelectedAvatar(iconName)}
              >
              <img src={`../../img/avatars/${iconName}`} alt="" />
            </div>
          ))}
        </div>

        <div className="update-wrapper grid grid-cols-12 items-center">
          <div className="col-span-6 grid justify-end pr-2">
            <button 
                className="form-submit-btn text-lg" 
                onClick={(e) => handleSubmit(e)}
                >
                Update
            </button>
          </div>
          <div className="col-span-6 grid justify-start pl-2">
            <div className="img-wrapper bg-neutral-200 rounded-full p-2">
              <Avatar 
                src={`../../img/avatars/${selectedAvatar}`}
                className="preview !w-[70px] !h-[70px] duration-500" 
                />
            </div>
          </div>
        </div>

        <button 
            className="btn-close-modal"
            onClick={() => props.setIsModalOpen(false)}
            >
            <CloseIcon className="relative bottom-[2px] mr-1"/>
            CLOSE
        </button>
    </Modal>
  )
}
