import React from 'react'
import TaskType from '../../../components/task/card/TaskType'
import { Star } from '@mui/icons-material'
import Icon from "@mui/material/Icon"
import { capitalizeFirstLetter } from '../../../services/stringCapitalizeFirstLetter'

export default function TypeScores({type, points, iconLigature}: {type: string, points: number, iconLigature: string}) {
  return (
    <div className="grid grid-cols-12 items-center text-[18px] py-2">
        <div className="col-span-5 text-right">
            <h3 className="">
                {capitalizeFirstLetter(type)}
            </h3>
        </div>
        <div className="col-span-2">
            <span className="ml-4">
                <Icon sx={{ fontSize: "22px", position:"relative",top:"2px" }}>{iconLigature}</Icon>
            </span>
        </div>
        <div className="col-span-3 text-right pr-4">
            <div className="">
                <span className="!text-white">{points}</span>
                <Star className="star-icon text-[gold] relative bottom-[2px] left-[2px]"/>
            </div>
        </div>
    </div>
  )
}
