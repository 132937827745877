import * as React from 'react';
import {useState} from 'react'
import PeopleTab from './components/assign_option/PeopleTab';
import { useAppDispatch } from '../../../../../store/store';
import { setIsStepperVisible } from '../../../../../store/features/taskPlayerSlice';
import OptionsTab from './components/assign_option/OptionsTab';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  

export default function SlideReadingAssignOption() {

    const dispatch = useAppDispatch();
    const [activeTab, setActiveTab] = useState('people')
    const [peopleTabScrollPosition, setPeopleTabScrollPosition] = useState(0)
    const [optionsTabScrollPosition, setOptionsTabScrollPosition] = useState(0)

    React.useEffect(() => {
        dispatch(setIsStepperVisible(false))
    }, [])


    const handleChange = (newActiveTab: string) => {
        setActiveTab(newActiveTab)
    };
    
    return (
        <div className="sr-assign-option-tabs">
            <div className="tabs-bar">
                <div className="tabs-wrapper">

                    <button 
                      onClick={() => {handleChange("people")}}
                      className={`${activeTab == "people" && "active"}`}>
                        PEOPLE
                    </button>

                    <button 
                      onClick={() => {handleChange("options")}}
                      className={`${activeTab == "options" && "active"}`}>
                        OPTIONS
                    </button>

                </div>
            </div>
            
              <div className={`tab-content
                ${activeTab == "people" && "tab-visible"}`}>
                  <PeopleTab 
                      activeTab={activeTab}
                      setPeopleTabScrollPosition={setPeopleTabScrollPosition}
                      peopleTabScrollPosition={peopleTabScrollPosition}
                  />
              </div>
            
             
              <div className={`tab-content
                ${activeTab == "options" && "tab-visible"}`}>
                <OptionsTab 
                    activeTab={activeTab}
                    setOptionsTabScrollPosition={setOptionsTabScrollPosition}
                    optionsTabScrollPosition={optionsTabScrollPosition}
                />
              </div>
            
            
        </div>
    );
}
