import { useEffect, useState, useRef } from "react";
import { useAppSelector } from "../../../../../../store/store";
import CheckIcon from '@mui/icons-material/Check';

interface Props{
    rawContent:string, 
    correct_answer:string, 
    wasSubmitted:boolean, 
    isFakeLoadingSubmit:boolean
}

export default function ScrollableOneWordPrompt({rawContent, correct_answer, wasSubmitted, isFakeLoadingSubmit}: Props) {

  const typedAnswer = useAppSelector((state) => state.taskPlayer.typedAnswer)
  const isSlideDone = useAppSelector((state) => state.taskPlayer.isSlideDone)
  const [showCorrectAnswer, setShowCorrectAnswer] = useState(false)
  const [isCorrect, setIsCorrect] = useState(false)
  const correctAnswers = correct_answer.split("|");
  const inputRef = useRef<HTMLInputElement|null>(null)

  const [firstHalfContent, setfirstHalfContent] = useState("");
  const [secondHalfContent, setSecondHalfContent] = useState("");

  useEffect(() => {
    let index = rawContent.indexOf("---");
    let firstHalf = rawContent.substring(0, index);
    let secondHalf = rawContent.substring(index + 3);

    setfirstHalfContent(firstHalf);
    setSecondHalfContent(secondHalf);
  }, [rawContent, typedAnswer])

  const checkIfCorrect = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.toLowerCase();
    let isCorrect = false;
    for (let index = 0; index < correctAnswers.length; index++) {        
        if(value.replace(/\s/g, '') == correctAnswers[index].toLowerCase().replace(/\s/g, '')){
            isCorrect = true;
        }
    }
    setIsCorrect(isCorrect);
    if(isCorrect){
        inputRef.current?.classList.add("correctAnswerGiven");
    }else{
        inputRef.current?.classList.remove("correctAnswerGiven");
    }
  }


  return (
    <div className={`tracking-tight text-[22px] mb-4 leading-8 rounded-md
        ${wasSubmitted && !isFakeLoadingSubmit && isCorrect && 'p-2 !bg-green-950 border-2 !border-green-200'}
        ${wasSubmitted && !isFakeLoadingSubmit && !isCorrect && 'p-2 !bg-red-950 border-2 !border-red-200'}`}>
        <div className="dark:text-white">
            {firstHalfContent}
            <input 
                type="text"
                ref={inputRef}
                className={`w-40 mx-1 px-1 font-bold rounded-md uppercase text-white text-center bg-primary
                    ${wasSubmitted && !isFakeLoadingSubmit && isCorrect && '!bg-green-800'}
                    ${wasSubmitted && !isFakeLoadingSubmit && !isCorrect && '!bg-red-800'}
                `}
                onChange={(e) => {
                    checkIfCorrect(e);
                }}
                disabled={wasSubmitted}
            />
            {secondHalfContent}
            {wasSubmitted && !isFakeLoadingSubmit && !isCorrect && (
                <div className="my-4 mx-2">
                    <div className="correct-answer bg-neutral-800 rounded-md  flex justify-center items-center">
                        {showCorrectAnswer && 
                            <>
                            <CheckIcon sx={{ mr: "5px" }} />
                            <span className="uppercase px-3 py-2">
                                {correctAnswers[0]}
                            </span>
                            </>
                        }
                        {!showCorrectAnswer && 
                            <button 
                                onClick={() => setShowCorrectAnswer(true)}
                                className="px-3 py-2">
                                Show Correct Answer
                            </button>
                        }
                    </div>
                </div>
            )}
        </div>
    </div>
  );
}
