import { DarkMode, Login } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { Dispatch, SetStateAction } from "react";

interface Props {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  handleThemeToggle: () => void;
}

export default function NavbarGuest({ setIsOpen, handleThemeToggle }: Props) {
  return (
    <ul className="navbar-list">
      <li className="inline-block">
        <Link to="/login" className="navbar-link text-white pl-0 mr-2">
          <Login className="icon" />
          <span className="hidden sm:inline-block">Login</span>
        </Link>
      </li>
      <li
        className="hover:cursor-pointer inline-block"
        onClick={handleThemeToggle}
      >
        <button
          className="navbar-link text-white flex px-0"
          onClick={() => setIsOpen(false)}
        >
          <DarkMode className="icon" />
        </button>
      </li>
    </ul>
  );
}
