import {useState,useRef, useEffect} from 'react'

interface Props{
    option: string;
    correctAnswer: string;
    inputName: string | undefined;
    wasSubmitted: boolean;
    isFakeLoadingSubmit: boolean;
}

export default function ScrollableAbcRadio({option, correctAnswer, inputName, wasSubmitted,isFakeLoadingSubmit}:Props) {
  
  const [isChecked, setIsChecked] = useState(false)
  const radioRef = useRef(null);    

    useEffect(() => {
        //@ts-ignore
        let isChecked = radioRef.current.checked;
        setIsChecked(isChecked);
    }, [wasSubmitted])


return (
    <div
    key={option}
    className={`flex relative mb-2  rounded-md py-2 pl-2 pr-[35px]
        ${!wasSubmitted ? 'bg-primary' : 'dark:bg-neutral-800 bg-neutral-500'}
        ${wasSubmitted && !isFakeLoadingSubmit && option == correctAnswer && 'border-4 !border-green-800'}
        ${wasSubmitted && !isFakeLoadingSubmit && isChecked && option != correctAnswer && '!bg-red-800'}
        ${wasSubmitted && !isFakeLoadingSubmit && isChecked && option == correctAnswer && '!bg-green-800'}
        ${wasSubmitted && isChecked && option == correctAnswer && 'correctAnswerGiven'}`}
    >
    <label className="text-white text-lg leading-6">{option}</label>
    <input
        ref={radioRef}
        type="radio"
        name={inputName}
        value={option}
        className="absolute right-2 top-[10px] w-5 h-5"
    />
    </div>
  )
}
