import {useState, Dispatch, SetStateAction} from 'react'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import Avatar from "@mui/material/Avatar";
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { API_URL } from '../../../Constants';
import { setActivityActiveTab } from '../../../store/features/uiSlice';


export default function ActivitySubTabs() {

const dispatch = useAppDispatch();
const avatarUrl = useAppSelector((state) =>state.auth.user!.avatar_file_name)

const activeTab = useAppSelector((state) => state.ui.activityActiveTab);

  return (
    <div className="activity-sub-tabs">
        <div className="tabs-wrapper">
            <div className={`tab ${activeTab == "all" && "active"}`}
                onClick={() => {
                    dispatch(setActivityActiveTab("all"));
                }}>
                    <PeopleAltIcon />
            </div>
            <div className={`tab ${activeTab == "user" && "active"}`}
                onClick={() => {
                    dispatch(setActivityActiveTab("user"));
                }}>
                <div className="bg-white rounded-full">
                    <Avatar 
                        src={`../../img/avatars/${avatarUrl}`}
                        className="!flex rounded-full !w-8 !h-8 border-2 border-white/30"
                    />
                </div>
            </div>
        </div>
    </div>
  )
}
