import {useEffect} from 'react'
import NavbarAuthStars from '../../../../components/navbar/auth/NavbarAuthStars'
import { Star } from '@mui/icons-material';
import TagIcon from '@mui/icons-material/Tag';
import Icon from "@mui/material/Icon";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import TypeScores from '../TypeScores';
import { capitalizeFirstLetter } from '../../../../services/stringCapitalizeFirstLetter';
import Loader from '../../../../components/utils/Loader';
import { useQuery } from 'react-query'
import { getUserTagPoints, getUserTypePoints } from '../../../../api/task'
import Avatar from "@mui/material/Avatar";
import { useAppSelector } from '../../../../store/store';

export default function TabUserScores() {

    const userAvatarFileName = useAppSelector((state) => state.auth.user!.avatar_file_name);
    const userName = useAppSelector((state) => state.auth.user!.name);
    const {data: typePoints, isLoading: typePointsLoading} = useQuery(["typePoints"], getUserTypePoints);
    const {data: tagPoints, isLoading: tagPointsLoading} = useQuery(["tagPoints"], getUserTagPoints);

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

    return (
    <div className="fade-in relative pt-[80px]">
        <div className="user-data-box absolute top-0 left-0 w-full justify-center py-4 shadow-lg  dark:shadow-neutral-800 bg-primary-light">
            <div className="flex justify-center">
            <Avatar
                src={`../../img/avatars/${userAvatarFileName}`}
                className="!flex rounded-full mr-2"
            />
            <div className='grid items-center white-text-with-shadow text-lg'>
                {userName}'s Scores
            </div>
            </div>
        </div>

            {/* <div className="grid grid-cols-12 items-center justify-center px-4 py-8 mb-8 max-w-[500px] bg-neutral-50 dark:bg-black">
                <div className="col-span-6">
                    <h2 className="heading !text-right pr-4 font-bold white-text-with-shadow">Current Star Progress</h2>
                </div>
                <div className="col-span-6 grid pl-4 items-center">                
                    <div className="star-pulse-and-shine-animation relative grid items-center justify-center bg-primary-light rounded-full w-[100px] h-[100px]">
                        <NavbarAuthStars showOnlyCurrent={true}/>
                    </div>
                </div>
            </div> */}

            <div className="user-scores-scrollable-wrapper overflow-y-scroll">
                <div className="points-by-type-wrapper px-6 max-w-[500px] mb-[30px] mt-6">
                    <div className=" m-2 rounded-lg text-white h-[275px] text-center shadow-lg white-text-with-shadow overflow-hidden bg-neutral-50 dark:bg-neutral-800">
                        <h2 className="heading py-4 font-bold white-text-with-shadow bg-secondary/90">
                            <Star sx={{ color:"yellow", position:"relative", top:"3px", mr:2 }} />
                            Score by Section
                            <TextSnippetIcon sx={{ position:"relative", top:"6px", ml:2 }} className="!h-5 !w-5 ml-4"/>
                        </h2>
                        {typePointsLoading && 
                            <div className="w-full grid justify-center py-4">
                                <Loader customClasses='dark:!text-white flex !text-neutral-400'/>
                            </div>
                        }
                        <div className="grid items-center py-4 min-w-[312px] ">
                            {typePoints && (
                                <>
                                <TypeScores 
                                    type="listening" 
                                    iconLigature="headphones" 
                                    points={typePoints.listening} />
                                <TypeScores 
                                    type="reading" 
                                    iconLigature="menu_book" 
                                    points={typePoints.reading} />
                                <TypeScores 
                                    type="speaking" 
                                    iconLigature="mic" 
                                    points={typePoints.speaking} />
                                <TypeScores 
                                    type="writing" 
                                    iconLigature="create" 
                                    points={typePoints.writing} />
                                </>
                            )}
                        </div>
                    </div>
                </div>

                <div className="px-6 max-w-[500px] pb-16">
                    <div className="text-center shadow-lg text-neutral-700 dark:text-white rounded-lg white-text-with-shadow overflow-hidden bg-neutral-50 dark:bg-neutral-800 pb-4">
                        <h2 className="heading py-4 font-bold white-text-with-shadow bg-secondary/90">
                            <Star sx={{ color:"yellow", position:"relative", top:"3px", mr:2 }} />
                            Score by Tag
                            <TagIcon sx={{ position:"relative", top:"6px", ml:2 }} className="!h-5 !w-5 ml-4"/>
                        </h2>
                        <div className="grid h-[275px] overflow-y-scroll dark:text-white text-lg text-left py-4 px-6 ">
                            {tagPointsLoading && 
                                <div className="w-full grid justify-center py-4">
                                    <Loader customClasses='dark:!text-white flex !text-neutral-400 flex'/>
                                </div>
                            }
                            <table>
                            <tbody>
                            {tagPoints && tagPoints.tags_with_points.map((twp:any) => (
                                <tr key={twp.tag.value} className="h-10">
                                    <td className='pr-2 pt-[8px]'>
                                        <Icon sx={{ fontSize: "18px" }}>{twp.tag.icon_ligature}</Icon>
                                    </td>
                                    <td className="pr-3">
                                        {capitalizeFirstLetter(twp.tag.value)}
                                    </td> 
                                    <td 
                                        className="text-left font-bold white-text-with-shadow"
                                    >
                                        {twp.points}
                                    </td>
                                </tr>
                            ))}

                            {tagPoints && tagPoints.zero_point_tags.map((zpt:any) => (
                                <tr key={zpt.value} className="h-10">
                                    <td className='pr-2'><Icon sx={{ fontSize: "18px" }}>{zpt.icon_ligature}</Icon></td>
                                    <td className="pr-3">{capitalizeFirstLetter(zpt.value)}</td>
                                    <td className="text-right font-bold white-text-with-shadow">0</td>
                                </tr>
                            ))}
                            </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
    </div>
    )
}
