import { useState } from 'react'
import { useCheckAccessCode } from '../../../../api/auth';
import { useQuery } from 'react-query';
import { setRegisterAccessCode, setRegisterActiveStep } from '../../../../store/features/authSlice';
import { useAppDispatch } from '../../../../store/store';
import Loader from '../../../../components/utils/Loader';
import { Link } from 'react-router-dom';

export default function Step1() {

    const dispatch = useAppDispatch();
    const checkAccessCode = useCheckAccessCode;

    const [enteredCode, setEnteredCode] = useState("")
    const [checkCodeResult, setCheckCodeResult] = useState< string | null>(null)
    const {isLoading, isRefetching , refetch: refetchCheckCode} = useQuery(["accessCodeCheck", enteredCode],
        () => checkAccessCode({enteredCode}),
        {enabled: false,
        onSuccess: (resp:{data:{status:number, msg: string}}) => {
            setCheckCodeResult(resp.data.msg);
            if(resp.data.status == 1){
                dispatch(setRegisterAccessCode(enteredCode));
                dispatch(setRegisterActiveStep(2))
            }
        }}
    );

    const validateAccessCode = () => {
        setCheckCodeResult(null);
        refetchCheckCode();
    }

  return (
    <>
        <h1 className="text-2xl mb-2 font-medium font-bricolage">Register</h1>
        <h2 className="text-lg mb-4">Please enter your access code:</h2>
        <div className="input-wrapper px-6">
            <form onSubmit={(e) => { e.preventDefault(); validateAccessCode(); } }
                className="mb-2">
                <input
                    disabled={isRefetching}
                    onChange={(e) => { setEnteredCode(e.target.value); } }
                    type="text" autoFocus className="form-input !bg-white !text-neutral-600 text-2xl font-medium text-center mb-4" />
                <button
                    type='submit'
                    className="form-submit-btn">
                        {(isRefetching || isLoading) && <Loader customClasses='!text-white !w-5 !h-5'/>}
                        {!isRefetching && !isLoading && <span>Submit</span>}
                </button>
            </form>
            {checkCodeResult &&
                <p className={`check-result text-white text-lg rounded-md py-2 my-4
                ${checkCodeResult == "Correct!" ? "bg-green-600" : "bg-primary"}`}>
                    <span>{checkCodeResult}</span>
                </p>
            }
        </div>
    </>
  )
}
