import {useState, useRef} from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../store/store';
import { setActiveWordIndex, setTypedAnswer, subtractSkipFromUserPerformance } from '../../../../../store/features/taskPlayerSlice';
import SkipperChances from './SkipperChances';


export default function Skipper({customStyles}: {customStyles?:string}) {
  const skipBtnRef = useRef(null);
  const taskSkipChances = useAppSelector((state) => state.taskPlayer.activeTask!.skip_chances);
  const [skipChances, setSkipChances] = useState(Number(taskSkipChances!))
  const activeTaskSlides = useAppSelector((state) => state.taskPlayer.activeTask!.slides)
  const activeSlideIndex = useAppSelector((state) => state.taskPlayer.activeSlideIndex)
  const activeWordIndex = useAppSelector((state) => state.taskPlayer.activeWordIndex)
  const activeTaskFormat = useAppSelector((state) => state.taskPlayer.activeTask!.format!.value)
  const activeSlideData = activeTaskSlides![activeSlideIndex];
  const dispatch = useAppDispatch();

  const handleSkipWord = () => {
    if(skipChances > 0){
      setSkipChances(skipChances - 1);
      if(activeTaskFormat.indexOf('speaking') >= 0){
        dispatch(setActiveWordIndex(activeWordIndex + 1))
        return
      }

      let firstCorrectAnswer = activeSlideData.correct_answer.split("|"); 
      dispatch(subtractSkipFromUserPerformance())
      dispatch(setTypedAnswer(firstCorrectAnswer[0]))
      
    }
  }

  const animateTap = () => {
    //@ts-ignore
    skipBtnRef.current.classList.add("animate-tap");
  
    setTimeout(() => {
      //@ts-ignore
      skipBtnRef.current.classList.remove("animate-tap");
    }, 500);
  };

  return (
    <button
      className={`${skipChances == 0 ? "!bg-neutral-500 !text-neutral-400 opacity-30" : ""} btn skipper-btn rounded-md pb-6 pt-2 px-5 ${customStyles}`}
      ref={skipBtnRef}
      onClick={(e) => {
        handleSkipWord();
        if(skipChances > 0){
          animateTap();
        }
      }}
    >
      <SkipperChances remainingChances={skipChances}/>
      Skip
    </button>
  )
}
