import {useEffect, useState} from 'react'
import { useAppSelector } from "../../../../../store/store";

export default function SkipperChances({remainingChances}:{remainingChances: number}) {

  const maxChances = useAppSelector((state) => state.taskPlayer.activeTask?.skip_chances);
  const [skipIcons, setSkipIcons] = useState<Array<JSX.Element> | undefined>()  

  useEffect(() => {
    setSkipIcons(generateSkipIcons())
  }, [maxChances, remainingChances])
  

  const generateSkipIcons = () => {
    let skipChancesIcons: Array<JSX.Element> = [];

    for (let chance = 1; chance <= Number(maxChances); chance++) {
      let isChanceRemaining = chance <= remainingChances;

      skipChancesIcons.push(
        <span key={chance} className={`${isChanceRemaining ? "bg-primary" : "bg-neutral-400"} rounded-full mr-1 w-[10px] h-[10px] flex items-center p-1`}>
        </span>
      )
    }

    return skipChancesIcons;
  }

  return (
    <div className="text-white flex justify-center absolute bottom-2 right-0 left-0">
      {skipIcons && skipIcons.map((icon) => ( icon ))}
    </div>
  )
}
