
export const finalLetterSMiddleware = (activeWord: string, typedAnswer:string) => {

    let typedAnswerArr = typedAnswer.split(" ");
    
    if(activeWord.toLowerCase().slice(-1) == "s"){
        let lastTypedAnswerWord = typedAnswerArr[typedAnswerArr.length - 1]
        let isLastTypedPartOfCorrect = lastTypedAnswerWord.indexOf(activeWord.substring(0, activeWord.length - 1)) == 0 ? true: false;
        
        if(isLastTypedPartOfCorrect && lastTypedAnswerWord !== activeWord){
            typedAnswerArr[typedAnswerArr.length - 1] = activeWord;
            let fixedAnswer = typedAnswerArr.join(" ");
            return fixedAnswer
        }
    }else{
        return false;
    }
}