import {Dispatch, SetStateAction, useState} from 'react'
import TaskType from '../../../components/task/card/TaskType'
import { StudentInfoProps } from '../../../types/adminTypes'
import { API_URL } from '../../../Constants'
import Avatar from "@mui/material/Avatar";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { capitalizeFirstLetter } from '../../../services/stringCapitalizeFirstLetter';
import { formatDistanceToNow } from 'date-fns';

interface Props{
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  setModalStudentData: Dispatch<SetStateAction<StudentInfoProps | null>>;
  data: StudentInfoProps;
}

export default function StudentInfoBox(props:Props) {

  return (
    <tr onClick={() => {
      props.setModalStudentData(props.data);
      props.setIsModalOpen(true);
    }}>
        <td className="">
            <Avatar 
                src={API_URL + props?.data?.avatar_url} alt=""
                className=""
            />
        </td>
        <td className='text-left'>{props.data.name}</td>
        <td>{props.data.processed_type_points?.listening}</td>
        <td>{props.data.processed_type_points?.reading}</td>
        <td>{props.data.processed_type_points?.speaking}</td>
        <td>{props.data.processed_type_points?.writing}</td>
        <td className="">{props.data.processed_type_points?.total}</td>
    </tr>
  )
}
