export const StringToJsx = ({string}: {string: string | null}) => {
    if(string == null){ return <></>}

    let jsxArr = string.split(" ").map((item, index) => {
      if(item == "<br/>"){ 
        return <br key={'content-item-' + index}/>
      }else{
        return <span key={'content-item-' + index}>
          {item + " "} 
        </span>
      }
    });

    return <>{jsxArr}</>
  }