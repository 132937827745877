import { useState, useEffect } from "react";
import StarIcon from "@mui/icons-material/Star";
import { useAppSelector } from "../../../store/store";

export default function NavbarAuthStars({showOnlyCurrent = false}: {showOnlyCurrent?: boolean}) {
  const userData = useAppSelector((state) => state.auth.user);
  const userStarPoints = userData?.stars !== undefined ? Number(userData!.stars) * 100 : 0;
  const userTotalPoints =  Number(userStarPoints) + Number(userData!.score);
  const [starFillHeight, setStarFillHeight] = useState("");

  const starsRow = [];
  let distanceRight = 22;
  let distanceIterator = distanceRight;
  //If user has 5 stars already, then he gets a golden ring
  // around his navbar Avatar. 
  let startsToBeShown = Number(userData?.stars) < 5 ? Number(userData?.stars) : Number(userData?.stars) % 5; 
  let startWidthAndHeight = showOnlyCurrent ? "75px" : "30px";

  useEffect(() => {
    setStarFillHeight(() => {
      let balancer = 15;
      if (Number(userData?.score) > 30) balancer = 0;
      if (Number(userData?.score) > 50) balancer = -10;
      return Number(userData?.score) + balancer + "%";
    });
  }, [userData]);


  for (let index = 0; index < startsToBeShown; index++) {
    starsRow.push(
      <StarIcon
        key={"star" + index}
        className="star-pulse-and-shine-animation"
        sx={{
          width: "30px",
          height: "30px",
          position: "absolute",
          color: "gold",
          stroke: "rgba(255,255,255,0.3)",
          strokeWidth: "1px",
          right: distanceIterator,
        }}
      />
    );
    distanceIterator += distanceRight;
  }

  return (
    <div className={`flex flex-1 relative items-end text-yellow-300 mr-12 mt-[-2px]
        ${showOnlyCurrent && "!mr-0"}`}>
      {!showOnlyCurrent && starsRow}
      {starFillHeight && (
        <div
          style={{ height: starFillHeight }}
          className={`absolute w-[40px] overflow-hidden 
                    ${showOnlyCurrent && "w-full bottom-[2px]"}`}
        >
          <StarIcon
            className="star-pulse-and-shine-animation"
            sx={{
              width: startWidthAndHeight,
              height: startWidthAndHeight,
              color: "gold",
              stroke: "rgba(255,255,255,0.3)",
              strokeWidth: "1px",
              zIndex: 100,
              position: "absolute",
              bottom: 0,
              left: 0,
            }}
          />
        </div>
      )}

      <StarIcon
        className="star-pulse-animation"
        sx={{
          width: startWidthAndHeight,
          height: startWidthAndHeight,
          color: "white",
          zIndex: 99,
        }}
      />
      <div style={{ backgroundColor:"gold" }} 
        className={`flex star-pulse-animation text-white absolute top-[3px] left-8 rounded-full w-[44px] h-[26px] text-sm items-center justify-center  z-[100] border-[1px] border-white
          ${showOnlyCurrent && "top-auto -bottom-4 left-auto -right-4 !text-lg w-[40px] h-[40px]"}`}>

        <span className="drop-shadow-[1px_1px_1px_rgba(0,0,0,1)] pt-[2px]">
          {userTotalPoints}<span className="text-[5px]"></span>
          {showOnlyCurrent && "%"}
        </span>

      </div>
    </div>
  );
}
