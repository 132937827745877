import EasySpeech from 'easy-speech'
import { useEffect, useRef, useState } from "react";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { useAppDispatch, useAppSelector } from "../../../../../store/store";
import { setIsAudioPlaying } from "../../../../../store/features/taskPlayerSlice";


const synth = window.speechSynthesis;


export default function Synthesizer({activeWord}: {activeWord:string}) {
  const synthesizerRef = useRef(null);
  const isTaskCompleted = useAppSelector((state) => state.taskPlayer.taskCompletedData)  
  const [isEnabled, setIsEnabled] = useState(true)
  const dispatch = useAppDispatch();

  useEffect(() => {
    EasySpeech.init({ maxTimeout: 5000, interval: 250 })
    .then(() => console.debug('load complete'))
    .catch((e:any) => console.error(e))
  }, [])
  

  useEffect(() => {
    if(activeWord == 'promptgap'){
        setIsEnabled(false)
    }else{
        if(!isEnabled){
            setIsEnabled(true)
        }
    }
  }, [activeWord])
  

  const handlePlayWord = async () => {
    if(isEnabled){
        //@ts-ignore
        synthesizerRef.current.classList.add("playing-sound-animation");

        dispatch(setIsAudioPlaying(true));

        let voices = synth.getVoices();
        let engVoice;
        for (let i = 0; i < voices.length; i++) {
            if(voices[i].lang.indexOf('en') >= 0){
                engVoice = voices[i];
                break;
            }
        }
        await EasySpeech.speak({
          text: activeWord.toLowerCase(),
          voice: engVoice, // optional, will use a default or fallback
          pitch: 1,
          rate: 1,
          volume: 1,
          end: () => {
            //@ts-ignore
            synthesizerRef.current.classList.remove("playing-sound-animation");
            dispatch(setIsAudioPlaying(false))
          }
        })
    }
  };


  if (!synth) {
    return (
      <div className="synthesizer-container">
        Browser does not Support Speech Synthesis.
      </div>
    );
  }

  if(isTaskCompleted){
    return <></>
  }


  return (
    <div className={`synthesizer-container overflow-visible
        ${!isEnabled && "opacity-20"}`
    }>
      <div
        className="synthesizer-icon-container"
        ref={synthesizerRef}
        onClick={handlePlayWord}
      >
        <VolumeUpIcon className="synthesizer-icon" />
      </div>
    </div>
  );
}
