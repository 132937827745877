import { useState, useRef, useEffect } from "react";
import StarIcon from "@mui/icons-material/Star";
import HomeIcon from "@mui/icons-material/Home";
import { Link } from "react-router-dom";
import { updateStoredScore, updateStoredStars } from "../../../store/features/authSlice";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import AddIcon from "@mui/icons-material/Add";
import Loader from "../../utils/Loader";
import { setActiveTask, setPlayRingCompleted } from "../../../store/features/taskPlayerSlice";
import { calculateGrade } from "./player_services/calculateGrade";
import { setFeedScrollPosition } from "../../../store/features/uiSlice";
import { getGradeBgColor } from "./player_services/getGradeColor";
import { API_URL } from "../../../Constants";
import Fireworks from "./slide/_animations/Fireworks";
import { formatDistanceToNowStrict } from "date-fns";
import TaskResultGrade from "../card/TaskResultGrade";
import { Star } from "@mui/icons-material";

export default function TaskCompleted() {

  const isDataLoaded = useAppSelector((state)=> state.taskPlayer.taskCompletedData);
  const startingScore = useAppSelector((state)=> state.auth.user!.score);
  const stars = useAppSelector((state)=> state.auth.user!.stars);
  const taskPoints = useAppSelector((state)=> state.taskPlayer.taskCompletedData!.points_won);
  const newScore = useAppSelector((state) => state.taskPlayer.taskCompletedData!.score)
  const result = useAppSelector((state) => state.taskPlayer.user_performance);
  const thumbnail_url = useAppSelector((state) => state.taskPlayer.activeTask?.thumbnail_url);
  const userData = useAppSelector((state) => state.auth.user);

  const startingScoreRef = useRef(Number(startingScore));
  const [shownValue, setShownValue] = useState(startingScoreRef.current);
  const [animateStar, setAnimateStar] = useState(false);
  const [addFullStar, setAddFullStar] = useState(false);
  const [starFillHeight, setStarFillHeight] = useState("");
  const [starWasAdded, setStarWasAdded] = useState(false);
  const [ringWasCompleted, setRingWasCompleted] = useState(false)
  
  const dispatch = useAppDispatch();
  const grade = calculateGrade(result);

  dispatch(setFeedScrollPosition(0));

  useEffect(() => {
    
    let mustAddPoints;
    if(starWasAdded){
      mustAddPoints = newScore > shownValue;
    }else{
      mustAddPoints = shownValue < Number(startingScore) + taskPoints && shownValue < 100;
    }
    
    if (mustAddPoints) {
        addPoints(taskPoints);
      } else if(!starWasAdded){
          dispatch( updateStoredScore({score: newScore}) );
          if(shownValue >= 100){
            let ringCompleted = Number(stars) == 4 || Number(stars) == 9;
            if(ringCompleted){
              setRingWasCompleted(true)
            }

            setTimeout(() => {
              setAddFullStar(true);
              setShownValue(0);
              setStarWasAdded(true);
            }, 1000);
            setTimeout(() => {
              setStarFillHeight((newScore) + "%");
              setAddFullStar(false);
              dispatch( updateStoredStars({stars: (Number(stars) + 1).toString()}))

              if(ringCompleted){
                setTimeout(() => {
                  dispatch( setPlayRingCompleted(true))
                }, 2000);
              }
            }, 1500);
          }
      }

      setStarFillHeight(() => {
        let hardFix = shownValue < 30 && shownValue > 0 ? 10 : -15;
        if(shownValue == 100){ hardFix = 0}
        return (shownValue + hardFix) + "%";
      });
  }, [shownValue]);

  const addPoints = (points: number) => {
    setTimeout(() => {
      setAnimateStar(true);
      setShownValue(shownValue + 1);
    }, 100);
  };

  if(!isDataLoaded){
    return <Loader />
  }

  return (
    <div className="px-2 pt-4 z-50">
      <div className={`grid justify-center text-lg text-center rounded-lg p-4  mb-6 shadow-lg  border-2 border-neutral-200 dark:border-neutral-800/30 dark:bg-neutral-900/80 bg-neutral-100 font-bricolage`}
        >

          <div className="pb-5 pr-6 absolute -left-6 -top-6 text-center bg-[gold] border-white/50 border-2 rounded-br-full w-[120px] h-[120px] mr-2 star-pulse-animation">
            <Star 
              className="star-pulse-and-shine-hard absolute bottom-[36px] right-[44px] !w-[44px] !h-[44px]  "
            />
            {/* <Star 
              className="star-pulse-and-shine-hard opacity-[0.4] absolute bottom-[15px] right-7 !w-[44px] !h-[44px]  "
            />
            <Star 
              className="star-pulse-and-shine-hard opacity-[0.4] absolute bottom-[15px] right-5 !w-[44px] !h-[44px]  "
            /> */}
          </div>

        <div className={`task-card-done-sash ${getGradeBgColor(result)} `}>
            <span className="mr-2">Grade</span>
            <span className="text-sm">
              <TaskResultGrade result={result}/>
            </span>
        </div>

        <div className="rounded-lg mx-4">
          <img 
            src={API_URL + thumbnail_url} 
            className="flex justify-self-center rounded-lg border-2 border-white dark:border-neutral-700/30 max-h-[170px]" 
          />
        </div>

        <h1 className="mt-4 mb-2 font-bold white-text-with-shadow">
          Task COMPLETED!
        </h1>

        {/* <div className="mt-2 mb-2"> Grade: 
          <div 
            className={`text-3xl ml-2 font-bold rounded-full w-[36px] h-[36px] inline-block ${getGradeBgColor(result)}`}
            >
            {grade}
          </div> 
        </div> */}
        <p className="mb-2 font-bold white-text-with-shadow">Well done {userData?.name}!</p>

      </div>
      <div className="flex relative justify-center items-end text-white dark:text-neutral-900 pt-0">
        {starFillHeight && (
          <div
            style={{ height: starFillHeight }}
            className={`absolute w-[180px] ${!addFullStar && "overflow-hidden"}`}
          >
            <StarIcon
              className={`${
                animateStar && "add-point-and-shine-animation stroke-[gold]"
              } ${addFullStar && "add-full-star-animation"}`}
              sx={{
                width: "180px",
                height: "180px",
                zIndex: 99,
                position: "absolute",
                bottom: 0,
                left: 0,
              }}
            />
          </div>
        )}

        <StarIcon
          className={`${
            animateStar &&
            "add-point-animation stroke-neutral-100 dark:stroke-neutral-800"
          }`}
          sx={{
            width: "180px",
            height: "180px",
            strokeWidth: "1px",
          }}
        />
        <span className="opacity-animation text-primary dark:text-white self-center font-bold rounded-full text-2xl absolute flex items-center justify-center z-[100]">
          <span className="text-white drop-shadow-[0_4px_3px_rgba(0,0,0,0.5)] dark:drop-shadow-[0_4px_3px_rgba(0,0,0,0.5)]">
            {shownValue}
            <span className="text-[8px]">%</span>
          </span>
        </span>
      </div>
      <div>
        <div className="opacity-animation text-primary dark:text-white text-center mb-8">
          <AddIcon className={"font-bold stroke-primary mb-1"} sx={{ strokeWidth:"2px" }} />
          <span className="font-size-animation  white-text-with-shadow font-bold text-lg">
            {taskPoints} {Number(taskPoints) > 1 ? "points" : "point"}
          </span>
        </div>
      </div>
      {!ringWasCompleted &&
        <div className="absolute bottom-4 left-0 w-full px-2">
          <button
            onClick={() => {
              window.history.back();
              dispatch(setActiveTask(null));
            }}

            className="bg-primary text-white w-full rounded-md py-4 flex items-center justify-center"
          >
            <HomeIcon sx={{ mr: 1 }} />
          </button>
        </div>
      }
      <Fireworks/>
    </div>
  );
}
