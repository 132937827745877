import axios from "axios";
import { API_URL } from "../Constants";
import { getToken } from "./_getToken";


export const getTasks = async (
  {task_level, task_type, task_tag, number_of_tasks}:
  {task_level: string, task_type:string, task_tag:string, number_of_tasks:number}
  ) => {
  let token = await getToken();
  let data = new FormData();
  data.append('task_level', task_level.toLowerCase());
  data.append('task_type', task_type);
  data.append('task_tag', task_tag);
  data.append('number_of_tasks', number_of_tasks.toString());
  

  const response = await axios.post(
    API_URL + "/api/get-all-tasks",
    data,
    {
      headers: {
        Accept :'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
}

export const getSlides = async (task_slug:string | undefined) => {
  let token = await getToken();

  const response = await axios.get(
    API_URL + "/api/get-task-with-slides/" + task_slug,
    {
      headers: {
        Accept :'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
}

export const getTaskLastDone = async (task_id:string | undefined) => {
  let token = await getToken();

  const response = await axios.get(
    API_URL + "/api/get-task-last-done/" + task_id,
    {
      headers: {
        Accept :'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
}


interface TaskCreateProps{
    title: string,
    level: string,
    type: string,
    format_id: string,
    points: string,
    skip_chances: string,
    tags_ids: string,
    thumbnail: File | null,
    audio_file: File | null,
    keep_audio_playing: string | null
}

export const useCreateTask = async ({ title, level, type, format_id, points, skip_chances, tags_ids, thumbnail, audio_file, keep_audio_playing}: TaskCreateProps) => {
    let token = await getToken();
    let data = new FormData();
    data.append('title', title);
    data.append('level', level);
    data.append('type', type);
    data.append('format_id', format_id);
    if(points !== undefined) data.append('points', points);
    if(skip_chances !== undefined) data.append('skip_chances', skip_chances);
    data.append('tags_ids', tags_ids);
    if(thumbnail !== null) data.append('img_file', thumbnail);
    if(audio_file !== null) data.append('audio_file', audio_file);
    if(keep_audio_playing !== null) data.append('keep_audio_playing', keep_audio_playing);
    
    
    const response = await axios.post(
      API_URL + "/api/create-task",
      data,
      {
        headers: {
          Accept :'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  };


  interface SlideProps{
      task_id: string,
      prompt: string;
      img_file: File | null;
      correct_answer: string;
      options: string;
      reading_text: string;
      time_start?: string;
      time_span?: string;
      is_option?: string;
  }

  export const useCreateSlide = async ({task_id, prompt, img_file, correct_answer, options, reading_text, time_start, time_span, is_option}: SlideProps) => {
    let token = await getToken();
    let data = new FormData();
    data.append('task_id', task_id);
    data.append('prompt', prompt);
    data.append('correct_answer', correct_answer);
    if(options.length) data.append('options', options);
    if(reading_text.length) data.append('reading_text', reading_text);
    if(img_file !== null) data.append('img_file', img_file);
    if(time_start !== undefined) data.append('time_start', time_start);
    if(time_span !== undefined) data.append('time_span', time_span);
    if(is_option !== undefined) data.append('is_option', is_option);
    
    const response = await axios.post(
      API_URL + "/api/create-slide",
      data,
      {
        headers: {
          Accept :'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  };


interface UpdateStarsProps{
  user_id: string | undefined;
  points: string;
}

  export const updateUserScore = async ({user_id, points}: UpdateStarsProps) => {
    let token = await getToken();
    let data = new FormData();
    if(user_id !== undefined){data.append('user_id', user_id)}
    data.append('points', points);
    
    const response = await axios.post(
      API_URL + "/api/update-user-score",
      data,
      {
        headers: {
          Accept :'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }


  interface RegisterTaskDoneProps{
    task_id: string | undefined;
    user_performance: number;
  }
  
  export const useRegisterTaskDone = async ({task_id, user_performance}: RegisterTaskDoneProps) => {
    let token = await getToken();

    let data = new FormData();
    if(task_id !== undefined){data.append('task_id', task_id)}
    if(user_performance !== undefined){data.append('user_performance', user_performance.toString())}
    
    const response = await axios.post(
      API_URL + "/api/register-task-done",
      data,
      {
        headers: {
          Accept :'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }


  export const getTags = async ({level}:{level:string}) => {
    let token = await getToken();

    let data = new FormData();
    if(level !== undefined){data.append('level', level)}

    const response = await axios.post(
      API_URL + "/api/get-tags",
      data,
      {
        headers: {
          Accept :'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }

  export const getTagsWithActiveTasks = async () => {
    let token = await getToken();
  
    const response = await axios.get(
      API_URL + "/api/get-tags-with-active-tasks",
      {
        headers: {
          Accept :'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }

  export const getFormats = async () => {
    let token = await getToken();
  
    const response = await axios.get(
      API_URL + "/api/get-formats",
      {
        headers: {
          Accept :'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }

  export const searchTasks = async ({typed_text}: {typed_text:string}) => {
    let token = await getToken();

    let data = new FormData();
    if(typed_text !== undefined){data.append('typed_text', typed_text)}
    
    const response = await axios.post(
      API_URL + "/api/search-task",
      data,
      {
        headers: {
          Accept :'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }

  export const getUserTasksDone = async ({user_id, number_of_tasks}:{user_id:string | undefined, number_of_tasks:number}) => {
    let token = await getToken();
  
    let data = new FormData();
    if(user_id !== undefined){data.append('user_id', user_id)}
    if(number_of_tasks !== undefined){data.append('number_of_tasks', number_of_tasks.toString())}

    const response = await axios.post(
      API_URL + "/api/user-tasks-done",
      data,
      {
        headers: {
          Accept :'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }

  export const getUserTypePoints = async () => {
    let token = await getToken();
  
    const response = await axios.post(
      API_URL + "/api/user-type-points",
      null,
      {
        headers: {
          Accept :'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }

  export const getUserTagPoints = async () => {
    let token = await getToken();
  
    const response = await axios.post(
      API_URL + "/api/user-tag-points",
      null,
      {
        headers: {
          Accept :'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }