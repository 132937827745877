
export const getGradeBgColor = (result: number) => {
    let bgColor;

    if(result >= 80){bgColor = 'bg-green-600'};
    if(result < 80){bgColor = 'bg-yellow-600'};
    if(result < 70){bgColor = 'bg-orange-600'};
    if(result < 60){bgColor = '!bg-red-600'};

    return bgColor;
}

export const getGradeTextColor = (result: number) => {
    let textColor;

    if(result >= 80){textColor =  'text-green-600'};
    if(result < 80){textColor = 'text-yellow-500'};
    if(result < 70){textColor = 'text-orange-500'};
    if(result < 60){textColor = '!text-red-500'};

    return textColor;
}