import {useState, FormEvent} from 'react'
import Loader from '../../components/utils/Loader';
import { useQuery } from 'react-query';
import { createProfile } from '../../api/auth';
import { useAppDispatch } from '../../store/store';
import { setUserData } from '../../store/features/authSlice';
import { User } from '../../types/userTypes';
import { LoginResponse } from '../../types/authTypes';
import { useNavigate } from 'react-router-dom';

export default function CreateProfile() {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("")
    const [error, setError] = useState<string | null>(null)
    
    const {data, isRefetching, refetch: refetchCreateProfile} = useQuery(["createProfile"], 
    () => createProfile({email,password,password_confirmation: passwordConfirmation}),
    {enabled: false,
    onSuccess: (resp:LoginResponse) => {
        if(resp.status == 1){    
            setEmail("");
            setPassword("");
            setPasswordConfirmation("");
            dispatch(setUserData(resp));
            navigate("/");
        }else{
            setError(resp.msg)
        }
    }})

    const handleCreateProfile = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        refetchCreateProfile();
    }

  return (
    <div className="flex flex-col items-center justify-center px-6 pt-6 pb-2 mx-auto lg:py-0">
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-black dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl text-center font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Create your profile
            </h1>
            <form className="space-y-4 md:space-y-6" onSubmit={(e) => handleCreateProfile(e)}>
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="form-input"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                  required
                  autoFocus
                />
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  className="form-input"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  value={password}
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Password Confirmation
                </label>
                <input
                  type="password"
                  name="password_confirmation"
                  id="password"
                  className="form-input"
                  onChange={(e) => {
                    setPasswordConfirmation(e.target.value);
                  }}
                  value={passwordConfirmation}
                  required
                />
              </div>
              <button type="submit" className="form-submit-btn">
                {isRefetching && <Loader setMarginTop={0} customClasses="!text-white !w-4 !h-4"/>}
                {!isRefetching && <span>Create</span>}
              </button>
              {error && <p className="bg-primary text-white rounded-md p-2">{error}</p>}
            </form>
          </div>
        </div>
      </div>
  )
}
