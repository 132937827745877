import {useEffect} from 'react'
import { Star } from "@mui/icons-material";
import Loader from "../../../../components/utils/Loader";
import { useQuery } from "react-query";
import { getGlobalRankingPoints } from "../../../../api/dashboard";
import { useAppSelector } from "../../../../store/store";
import { User } from "../../../../types/userTypes";
import Avatar from "@mui/material/Avatar";
import { API_URL } from "../../../../Constants";
import TodayIcon from "@mui/icons-material/Today";
import DateRangeIcon from "@mui/icons-material/DateRange";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

export default function TabAllScores() {
  const level = useAppSelector((state) => state.auth.user!.level);

  const { data: rankings, isLoading: globalRankingLoading } = useQuery(
    ["rankings"],
    () => getGlobalRankingPoints({ level })
  );

  useEffect(() => {
    window.scrollTo(0,0);
  }, [])
  

  return (
    <div className="tab-all-points fade-in pt-10">
      <div className="pb-16 max-w-[500px] ">

        {/* Today's Ranking */}
        <div className="grid w-[320px] text-center dark:bg-neutral-800 bg-neutral-100 rounded-lg overflow-hidden mb-12 shadow-lg">
          <h2 className="heading text-left py-4 font-medium text-white bg-secondary/90">
            <TodayIcon
              sx={{ color: "white", position: "relative", top: "3px", mr: "16px" }}
            />
            Today's Ranking
            <Star
              sx={{ color: "yellow", position: "relative", top: "3px", ml: "16px" }}
            />
          </h2>
          <div className="grid items-center max-h-[195px] dark:text-white  px-2 text-lg text-left ">
            {globalRankingLoading && (
              <div className="w-full grid justify-center py-4">
                <Loader customClasses="!text-neutral-300 flex" />
              </div>
            )}
            {rankings?.today.length == 0 && (
              <p className="text-center py-2">No records were found</p>
            )}
            {rankings?.today.length > 0 && (
              <div className="ranking-table">
                {rankings &&
                  rankings.today.map((user: User) => (
                    <div
                      key={user.id}
                      className="ranking-table-row grid grid-cols-12 items-center mb-4"
                    >
                      <div className="col-span-2">
                        <Avatar
                          src={`../../img/avatars/${user.avatar_file_name}`}
                          className="!flex rounded-full"
                        />
                      </div>
                      <div className="col-span-6 pl-3 pr-2">
                        <span className="font-medium white-text-with-shadow">
                          {user.name.split(" ")[0]}{" "}
                          {user.name.split(" ")[1]?.substring(0, 1)}
                        </span>
                      </div>
                      <div className="col-span-4 grid justify-center text-center pr-2">
                        <span className="font-bold white-text-with-shadow">
                          {user.today_points}{" "}
                          {/* <Star
                            sx={{
                              color: "yellow",
                              position: "relative",
                              bottom: "2px",
                              width: "16px",
                              height: "16px !important",
                            }}
                          /> */}
                        </span>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>


        {/* Week's Ranking */}
        <div className="week-ranking grid w-[320px] text-center dark:bg-neutral-800 bg-neutral-100 rounded-lg overflow-hidden mb-12 shadow-lg">
          <h2 className="heading py-4 font-medium text-white bg-secondary/90">
            <DateRangeIcon
              sx={{ color: "white", position: "relative", top: "3px", mr: "16px" }}
            />
            Week's Ranking
            <Star
              sx={{ color: "yellow", position: "relative", top: "3px", ml: "16px" }}
            />
          </h2>
          <div className="grid items-center max-h-[195px] dark:text-white px-2 text-lg text-left ">
            {globalRankingLoading && (
              <div className="w-full grid justify-center py-4">
                <Loader customClasses="!text-neutral-300 flex" />
              </div>
            )}
            {rankings?.week.length == 0 && (
              <p className="text-center py-2">No records were found</p>
            )}
            {rankings?.week.length > 0 && (
              <div className="ranking-table">
                {rankings &&
                  rankings.week.map((user: User) => (
                    <div
                      key={user.id}
                      className="ranking-table-row grid grid-cols-12 items-center mb-4"
                    >
                      <div className="col-span-2">
                        <Avatar
                          src={`../../img/avatars/${user.avatar_file_name}`}
                          className="!flex rounded-full"
                        />
                      </div>
                      <div className="col-span-6 px-2 ">
                        <span className="font-medium text-white [text-shadow:_1px_1px_1px_#000]">
                          {user.name.split(" ")[0]}{" "}
                          {user.name.split(" ")[1]?.substring(0, 1)}
                        </span>
                      </div>
                      <div className="col-span-4 grid justify-end text-right pr-2">
                        <span className="font-bold text-white [text-shadow:_1px_1px_1px_#000]">
                          {user.week_points}{" "}
                          <div className="medal-wrapper"></div>
                          {/* <Star
                            sx={{
                              color: "yellow",
                              position: "relative",
                              bottom: "2px",
                              width: "16px",
                              height: "16px !important",
                            }}
                          /> */}
                        </span>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>


        {/* Global Ranking */}
        <div className="grid w-[320px] text-center dark:bg-neutral-800 bg-neutral-100 rounded-lg overflow-hidden shadow-lg">

          <h2 className="heading py-4 font-bold text-white bg-primary ">
            <CalendarMonthIcon
              sx={{ color: "white", position: "relative", top: "3px", mr: "16px" }}
            />
            Global Ranking
            <Star
              sx={{ color: "yellow", position: "relative", top: "3px", ml: "16px" }}
            />
          </h2>

          <div className="ranking-table-wrapper global grid items-center max-h-[195px] dark:text-white text-lg text-left bg-primary">
            {globalRankingLoading && (
              <div className="w-full grid justify-center py-4">
                <Loader customClasses="!text-neutral-300 flex" />
              </div>
            )}
            {rankings?.global.length == 0 && (
              <p className="text-center py-2">No records were found</p>
            )}
            {rankings?.global.length > 0 && (
              <div className="ranking-table global">
                {rankings &&
                  rankings.global.map((user: User) => (
                    <div
                      key={user.id}
                      className="ranking-table-row grid grid-cols-12 items-center px-2"
                    >
                      <div className="col-span-2 grid justify-end rounded-full">
                        <Avatar
                          src={`../../img/avatars/${user.avatar_file_name}`}
                          className="!flex rounded-full"
                        />
                      </div>
                      <div className="col-span-6 px-3">
                        <span className="font-medium text-white [text-shadow:_1px_1px_3px_#000]">
                          {user.name.split(" ")[0]}{" "}
                          {user.name.split(" ")[1]?.substring(0, 1)}
                        </span>
                      </div>
                      <div className="col-span-4 text-right pr-2">
                        <span className="font-bold relative bottom-[5px] text-white [text-shadow:_1px_1px_3px_#000]">{user.total_points}</span>
                        <div className="trophy-wrapper"></div>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>

        </div>
      </div>
    </div>
  );
}
