import {useEffect, useState} from 'react'
import { useQuery } from "react-query";
import { getUserTasksDone } from "../../../../api/task";
import { TaskDone } from "../../../../types/taskTypes";
import Loader from "../../../../components/utils/Loader";
import { useAppSelector } from "../../../../store/store";
import TaskDoneCard from '../../../activity/components/TaskDoneCard';
import StudentTaskDoneCard from './StudentTasksDoneCard';


export default function StudentInfoActivity({user_id}:{user_id?: string}) {

  const scrollPosition = useAppSelector((state) => state.ui.dashboardScrollPosition);
  const tasksToShow = 20;

  const { data: tasksDone, isLoading } = useQuery(
    ["userTasksDone", {user_id, tasksToShow}], 
    () => getUserTasksDone({user_id, number_of_tasks: tasksToShow})
  );
  const [isScrollSet, setIsScrollSet] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, scrollPosition);
      setIsScrollSet(true)
    }, 100);
  }, [scrollPosition])


  if (isLoading) {
    return (
      <div className="w-full text-center pt-10">
        <Loader  customClasses='!text-primary !w-12 !h-12' />
      </div>
    );
  }

  return (
    <div className={`dashboard lg:px-[20%] text-neutral-400 text-center pb-16 
        ${!isScrollSet ? "invisible" : "fade-in-animation"} `}>
      {tasksDone && tasksDone.length == 0 && 
        <div className="pt-8 text-lg text-neutral-700 dark:text-neutral-200">
          <p>The user hasn't done any tasks yet.</p>
        </div>
      }
      {tasksDone && tasksDone.tasks.map((td:TaskDone, index: number) => {
        if(td.task !== null){
          return (
            <StudentTaskDoneCard td={td}/>
          )
        }
      })}
    </div>
  )
}
