import {useState, useEffect, useRef} from 'react'
import { SlideProps } from "../../../../../../../types/taskTypes";
import { useAppDispatch, useAppSelector } from "../../../../../../../store/store";
import Loader from "../../../../../../utils/Loader";
import SendIcon from '@mui/icons-material/Send';
import FeedIcon from '@mui/icons-material/Feed';
import { setTaskCompletedData, setUserPerformance } from '../../../../../../../store/features/taskPlayerSlice';
import { useRegisterTaskDone } from '../../../../../../../api/task';
import { useMutation } from 'react-query';
import AssignOptionOption from './items/AssignOptionOption';
import AssignOptionPerson from './items/AssignOptionPerson';
// import ScrollableOneWordPrompt from './components/ScrollableOneWordPrompt';

interface Props{
    activeTab:string, 
    setPeopleTabScrollPosition: (position:number) => void,
    peopleTabScrollPosition: number
}

export default function PeopleTab({activeTab, setPeopleTabScrollPosition, peopleTabScrollPosition}:Props) {

const slides = useAppSelector((state) =>state.taskPlayer.slides)
const peopleSlides = slides.filter((slide) => {
    return !slide.is_option
})
  const [wasSubmitted, setWasSubmitted] = useState(false)
  const [doneWasRegistered, setDoneWasRegistered] = useState(false)
  const [isFakeLoadingSubmit, setIsFakeLoadingSubmit] = useState(false)
  const [showSubmitBtn, setShowSubmitBtn] = useState(true)
  const [fadeOutTransition, setFadeOutTransition] = useState(false)
  const task_id = useAppSelector((state) => state.taskPlayer.activeTask?.id);
  const dispatch = useAppDispatch();
  const { mutate: registerTaskDone, isLoading: taskCompletedLoading } = useMutation(useRegisterTaskDone);
  const [tempTaskCompletedData, setTempTaskCompletedData] = useState(null)
  const scrollableDiv = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    scrollableDiv.current!.scrollTop = peopleTabScrollPosition;
  }, [activeTab])

  useEffect(() => {
    if(wasSubmitted){
        setTimeout(() => {
            let correctAnswers = document.getElementsByClassName("correctAnswerGiven");
            let wrongAnswers = peopleSlides.length - correctAnswers.length;
            let wrongPercentage = wrongAnswers * 100/ peopleSlides.length;
            let user_performance = 100 - wrongPercentage;
            
            dispatch(setUserPerformance(user_performance))      

            if(!doneWasRegistered){
                setTimeout(() => {
                    registerTaskDone({task_id, user_performance}, { onSuccess: (data) => {      
                        setTempTaskCompletedData(data);
                        setDoneWasRegistered(true)
                    }});
                }, 500);
            }
        }, 1000);
    }
  }, [wasSubmitted])


  const handleSubmitAnimation = () => {
    setWasSubmitted(true)
    setIsFakeLoadingSubmit(true);

    setTimeout(() => {
        setShowSubmitBtn(false)
        setIsFakeLoadingSubmit(false)
        scrollableDiv.current!.scrollTop = 0;
    }, 2000);
  }

  const handleSeeResults = () => {
    setFadeOutTransition(true)
    setTimeout(() => {
        // dispatch(setIsTaskCompleted(true))
        dispatch(setTaskCompletedData(tempTaskCompletedData));
    }, 1500);
  }

  if (slides === undefined) {
    return <Loader />;
  }

  return (
    <div
        onScroll={() => {setPeopleTabScrollPosition(scrollableDiv.current!.scrollTop)}} 
        ref={scrollableDiv} 
        className={`overflow-y-scroll  max-h-[calc(100vh-70px)]
          ${fadeOutTransition && "fade-out"}`}>
        <div className={`w-full ${isFakeLoadingSubmit ? 'fade-out' : 'fade-in'}`}>
            {peopleSlides && peopleSlides.map((slide: SlideProps, index) => (
                <div key={slide.id}>
                    <AssignOptionPerson 
                      slide={slide}
                      wasSubmitted={wasSubmitted}
                      isFakeLoadingSubmit={isFakeLoadingSubmit}/>
                </div>
            ))}
        </div>
      <hr className={`mt-8 mb-8 ${wasSubmitted && "border-transparent"}`}/>
      <div className={`grid items-center justify-center z-[200] pb-8
        ${!showSubmitBtn && 'fixed w-full rounded-none bottom-[50px] left-0 mb-0 pb-0'} `}>
        {showSubmitBtn &&
            <button className='form-submit-btn w-[70vw] max-w-[300px] p-2 text-lg border-2 border-white'
                onClick={() => handleSubmitAnimation()}>
                    {!isFakeLoadingSubmit && 
                    <span>
                        Submit {" "}
                        <SendIcon sx={{ml:"5px", mb:"3px"}}/>
                    </span>
                    }
                    {isFakeLoadingSubmit && 
                    <Loader setMarginTop={0} customClasses="!text-white !w-5 !h-5"/>}
            </button>
        }

        {!showSubmitBtn &&
            <button className='form-submit-btn see-final-grade-btn w-[100vw] lg:w-[60vw] rounded-none'
                onClick={() => handleSeeResults()}>
                See final grade{" "}
                <FeedIcon sx={{ml:"5px", mb:"3px"}}/>
            </button>
        }
      </div>
    </div>
  );
}
