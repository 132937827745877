
export const trickyWordsMiddleware = (activeWord: string, givenAnswer:string) => {
    
    //Words that the Web Speech Recognition system gets wrong
    const trickyWords = [
        {intended: "he", missinterpretation: "she" },
        {intended: "center", missinterpretation: "centre" },
        {intended: "centre", missinterpretation: "center" },
        {intended: "eco", missinterpretation: "echo" },
        {intended: "this", missinterpretation: "these" },
        {intended: "these", missinterpretation: "this" }
    ];

    let fixedAnswer = "";

    for(let i = 0; i < trickyWords.length; i++) {        
        if(activeWord.toLowerCase() == trickyWords[i].intended.toLowerCase()){
            if(givenAnswer.toLowerCase().indexOf(trickyWords[i].missinterpretation.toLowerCase()) >= 0){
                fixedAnswer = givenAnswer.replace(
                    trickyWords[i].missinterpretation, trickyWords[i].intended
                );
            }
        }
    }

    return fixedAnswer !==  givenAnswer ? fixedAnswer : false;
}