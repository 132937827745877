import React, { InputHTMLAttributes } from 'react'
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';
import { setIsSearchInputExtended, setIsSearching, setSearchResults } from '../../../../store/features/feedSlice';
import { searchTasks } from '../../../../api/task';
import { useQuery } from 'react-query';

export default function SearchInput() {

  const [typedText, setTypedText] = React.useState("")


  const dispatch = useAppDispatch();

  const {data:searchResults, refetch: refetchSearch, } = useQuery(
    ["searchTasks", {typedText}], 
    () => searchTasks({typed_text: typedText}),
    {
      enabled: false, 
      onSuccess: (results) => {
        dispatch(setIsSearching(false))
        if(typedText.length > 0){
          dispatch(setSearchResults(results))
        }
      }
    },
    
  );
  
  React.useEffect(() => {
    dispatch(setSearchResults([]))
    if(typedText.length > 0){
      dispatch(setIsSearching(true))
      setTimeout(() => {
        refetchSearch();
      }, 1000);
    }else{
      dispatch(setSearchResults(null))
      dispatch(setIsSearching(false))
    }
  }, [typedText])


  return (
    <>

    <input
      type="text"
      className="w-full text-neutral-700   outline-none rounded-md py-1 pl-2 pr-10 mx-2 menu-fade-in h-[85%] border-2 border-neutral-300 "
      placeholder="Search task by title..."
      autoFocus 
      onChange={(e) => {setTypedText(e.target.value)}}/>
    <SearchIcon sx={{ w: 16, h: 16 }} className="menu-fade-in absolute right-[54px]"/>
    <button  
      className="filter-button rounded-full min-w-0 min-h-0 !bg-transparent text-white menu-fade-in"
      onClick={() => dispatch(setIsSearchInputExtended(false))}
      >
      <CancelIcon className='!w-8 !h-8'/>
    </button>
  </>
  )
}
