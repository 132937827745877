import { API_URL } from "../../../../Constants";
import { SlideProps } from "../../../../types/taskTypes";

interface Props{
    slides: SlideProps[],
    activeSlideIndex: number
}

export const preloadNextSlideImage = ({slides, activeSlideIndex}: Props) => {

    if(activeSlideIndex < slides.length - 1){
        const img = new Image();
        img.src = API_URL + slides[activeSlideIndex + 1].img_url;
        return img;
    }

};
