import {useState, useEffect, useRef} from 'react'
import { SlideProps } from "../../../../../../types/taskTypes";
import ScrollableAbcRadio from './ScrollableAbcRadio';

export default function ScrollableAbcRadioSelect({slide, wasSubmitted, isFakeLoadingSubmit}: {slide: SlideProps, wasSubmitted:boolean, isFakeLoadingSubmit:boolean}) {

  const correctAnswer = slide.correct_answer;
    
  return (
    <div className="grid px-1">
        {slide.options?.split("|").map((option: string) => (
            <ScrollableAbcRadio
                key={option}
                option={option} 
                correctAnswer={correctAnswer}
                inputName={slide.id}
                wasSubmitted={wasSubmitted}
                isFakeLoadingSubmit={isFakeLoadingSubmit}
                />
        ))}
    </div>
  );
}
