import {useState} from 'react'
import { useQuery } from 'react-query';
import { useRegister } from '../../../../api/auth';
import { useAppDispatch, useAppSelector } from '../../../../store/store';
import Loader from '../../../../components/utils/Loader';
import { useNavigate } from 'react-router-dom';
import { setRegisterActiveStep, setUserData } from '../../../../store/features/authSlice';
import { User } from '../../../../types/userTypes';

export default function Step3() {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const registerUser = useRegister;
    const level = useAppSelector((state) => state.auth.register.level)
    const access_code = useAppSelector((state) => state.auth.register.access_code)
    const [name, setName] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const handleUserRegister = async () => {
        setIsLoading(true)
        const response = await registerUser({
          name, level,access_code
        });
        setIsLoading(false)
        if(response.data.status == 1){
          await dispatch(setUserData(response.data));
          dispatch(setRegisterActiveStep(1))
          navigate('/');
        }
      }

  return (
    <>
        <h1 className="text-2xl mb-4 pt-6 font-bricolage">Tell us your name</h1>
        <div className="input-wrapper px-6">
            <form onSubmit={(e) => { e.preventDefault(); handleUserRegister(); } }
                className="mb-2">
                <input  type="text" autoFocus disabled={isLoading}
                    onChange={(e) => { setName(e.target.value); } }
                    className="form-input !bg-white !text-neutral-700 text-2xl font-medium text-center mb-4" />
                <button
                    type='submit'
                    className="form-submit-btn">
                        {isLoading && <Loader customClasses='!text-white !w-5 !h-5'/>}
                        {!isLoading && <span>Submit</span>}
                </button>
            </form>
        </div>
    </>
  )
}
