import {useEffect, useState} from "react";

export default function Fireworks() {
  const [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(false)
    }, 2700);
  }, [isVisible])
  
  return (
    <div className={`pyro 
      ${!isVisible && 'fade-out'}
    `}>
      <div className="before"></div>
      <div className="after"></div>
    </div>
  );
}
