import {useState, useEffect} from 'react'
import { SlideProps } from "../../../../../types/taskTypes";
import { useAppDispatch, useAppSelector } from "../../../../../store/store";
import Loader from "../../../../utils/Loader";
import ScrollableAbcRadioSelect from './components/ScrollableAbcRadioSelect';
import SendIcon from '@mui/icons-material/Send';
import FeedIcon from '@mui/icons-material/Feed';
import { setIsStepperVisible, setTaskCompletedData, setUserPerformance } from '../../../../../store/features/taskPlayerSlice';
import { useRegisterTaskDone } from '../../../../../api/task';
import { useMutation } from 'react-query';

export default function SlideListeningScrollableAbc({ slides }: { slides: SlideProps[] }) {

  const [wasSubmitted, setWasSubmitted] = useState(false)
  const [doneWasRegistered, setDoneWasRegistered] = useState(false)
  const [isFakeLoadingSubmit, setIsFakeLoadingSubmit] = useState(false)
  const [showSubmitBtn, setShowSubmitBtn] = useState(true)
  const [fadeOutTransition, setFadeOutTransition] = useState(false)
  const task_id = useAppSelector((state) => state.taskPlayer.activeTask?.id);
  const dispatch = useAppDispatch();
  const { mutate: registerTaskDone, isLoading: taskCompletedLoading } = useMutation(useRegisterTaskDone);
  const [tempTaskCompletedData, setTempTaskCompletedData] = useState(null)
  

    useEffect(() => {
        dispatch(setIsStepperVisible(false))
    }, [])

  useEffect(() => {
    if(wasSubmitted){
        setTimeout(() => {
            let correctAnswers = document.getElementsByClassName("correctAnswerGiven");
            let wrongAnswers = slides.length - correctAnswers.length;
            let wrongPercentage = wrongAnswers * 100/ slides.length;
            let user_performance = 100 - wrongPercentage;
            dispatch(setUserPerformance(user_performance))       

            if(!doneWasRegistered){
                setTimeout(() => {
                    registerTaskDone({task_id, user_performance}, { onSuccess: (data) => {      
                        setTempTaskCompletedData(data);
                        setDoneWasRegistered(true)
                    }});
                }, 500);
            }
        }, 1000);
    }
  }, [wasSubmitted])


  const handleSubmitAnimation = () => {
    setWasSubmitted(true)
    setIsFakeLoadingSubmit(true);

    setTimeout(() => {
        setShowSubmitBtn(false)
        setIsFakeLoadingSubmit(false)
    }, 2000);
  }

  const handleSeeResults = () => {
    setFadeOutTransition(true)
    setTimeout(() => {
        // dispatch(setIsTaskCompleted(true))
        dispatch(setTaskCompletedData(tempTaskCompletedData));
    }, 1500);
  }

  if (slides === undefined) {
    return <Loader />;
  }

  return (
    <div className={`px-2 mt-28 overflow-y-scroll max-w-[720px] max-h-[calc(100vh-170px)] pb-8
        ${fadeOutTransition && "fade-out"}`}>
        <div className={`slides-wrapper
            ${isFakeLoadingSubmit ? 'fade-out' : 'fade-in'}`}>
            {slides && slides.map((slide, index) => (
                <div key={slide.id} className=''>
                    <div className="grid grid-cols-12 text-gray-700 dark:text-white text-[20px] mb-8 mt-6 leading-6">
                        <div className="col-span-1">
                            <span>{index + 1}{") "}</span>
                        </div>
                        <div className="col-span-11">
                            <p className="relative z-50">
                                {slide?.prompt}
                            </p>
                        </div>
                    </div>
                    <ScrollableAbcRadioSelect slide={slide} wasSubmitted={wasSubmitted} isFakeLoadingSubmit={isFakeLoadingSubmit}/>
                </div>
            ))}
      </div>
      <hr className="mt-4 mb-8"/>
      <div className={`w-full grid items-center justify-center mb-2 z-[200]
        ${!showSubmitBtn && 'fixed bottom-0 left-0 dark:bg-black bg-white h-[90px] mb-0'} `}>
        {showSubmitBtn &&
            <button className='form-submit-btn w-[70vw] max-w-[300px] p-2 text-lg border-2 border-white'
                onClick={() => handleSubmitAnimation()}>
                    {!isFakeLoadingSubmit && 
                    <span>
                        Submit {" "}
                        <SendIcon sx={{ml:"5px", mb:"3px"}}/>
                    </span>
                    }
                    {isFakeLoadingSubmit && 
                    <Loader setMarginTop={0} customClasses="!text-white !w-5 !h-5"/>}
            </button>
        }

        {!showSubmitBtn &&
            <button className='form-submit-btn see-final-grade-btn w-[70vw]'
                onClick={() => handleSeeResults()}>
                See final grade{" "}
                <FeedIcon sx={{ml:"5px", mb:"3px"}}/>
            </button>
        }
      </div>
    </div>
  );
}
