import {useState, useEffect, useRef} from 'react'
import { SlideProps } from "../../../../../../../types/taskTypes";
import { useAppDispatch, useAppSelector } from "../../../../../../../store/store";
import Loader from "../../../../../../utils/Loader";
import SendIcon from '@mui/icons-material/Send';
import FeedIcon from '@mui/icons-material/Feed';
import { setTaskCompletedData, setUserPerformance } from '../../../../../../../store/features/taskPlayerSlice';
import { useRegisterTaskDone } from '../../../../../../../api/task';
import { useMutation } from 'react-query';
import { StringToJsx } from '../../../_components/StringToJsx';
import Prompt from './items/SR_MS_Prompt';

interface Props{
    activeTab:string, 
    setPromptTabScrollPosition: (position:number) => void,
    promptTabScrollPosition: number
}

export default function PromptTab({activeTab, setPromptTabScrollPosition, promptTabScrollPosition}:Props) {

const slides = useAppSelector((state) =>state.taskPlayer.slides)
const promptSlides = slides.filter((slide) => {
    return !slide.is_option
})
  const [wasSubmitted, setWasSubmitted] = useState(false)
  const [doneWasRegistered, setDoneWasRegistered] = useState(false)
  const [isFakeLoadingSubmit, setIsFakeLoadingSubmit] = useState(false)
  const [showSubmitBtn, setShowSubmitBtn] = useState(true)
  const [fadeOutTransition, setFadeOutTransition] = useState(false)
  const [showCorrectAnswer, setShowCorrectAnswer] = useState(false)

  const task_id = useAppSelector((state) => state.taskPlayer.activeTask?.id);
  const taskTitle = useAppSelector((state) => state.taskPlayer.activeTask?.title)
  const dispatch = useAppDispatch();
  const { mutate: registerTaskDone, isLoading: taskCompletedLoading } = useMutation(useRegisterTaskDone);
  const [tempTaskCompletedData, setTempTaskCompletedData] = useState(null)
  const scrollableDiv = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    scrollableDiv.current!.scrollTop = promptTabScrollPosition;
  }, [activeTab])

  useEffect(() => {
    if(wasSubmitted){
        setTimeout(() => {
            let correctAnswers = document.getElementsByClassName("correctAnswerGiven");
            let wrongAnswers = promptSlides.length - correctAnswers.length;
            let wrongPercentage = wrongAnswers * 100/ promptSlides.length;
            let user_performance = 100 - wrongPercentage;
            
            dispatch(setUserPerformance(user_performance))      

            if(!doneWasRegistered){
                setTimeout(() => {
                    registerTaskDone({task_id, user_performance}, { onSuccess: (data) => {      
                        setTempTaskCompletedData(data);
                        setDoneWasRegistered(true)
                    }});
                }, 500);
            }
        }, 1000);
    }
  }, [wasSubmitted])


  const handleSubmitAnimation = () => {
    setWasSubmitted(true)
    setIsFakeLoadingSubmit(true);

    setTimeout(() => {
        setShowSubmitBtn(false)
        setIsFakeLoadingSubmit(false)
        scrollableDiv.current!.scrollTop = 0;
    }, 2000);
  }

  const handleSeeResults = () => {
    setFadeOutTransition(true)
    setTimeout(() => {
        // dispatch(setIsTaskCompleted(true))
        dispatch(setTaskCompletedData(tempTaskCompletedData));
    }, 1500);
  }

  if (slides === undefined) {
    return <Loader />;
  }

  return (
    <div
        onScroll={() => {setPromptTabScrollPosition(scrollableDiv.current!.scrollTop)}} 
        ref={scrollableDiv} 
        className={`overflow-y-scroll  max-h-[calc(100vh-70px)]
          ${fadeOutTransition && "fade-out"}
          ${!showSubmitBtn && "pb-32"}`}>
        <div className={`w-full px-3 ${isFakeLoadingSubmit ? 'fade-out' : 'fade-in'}`}>
          <h2 className="dark:text-white text-neutral-800 text-center font-serif text-2xl my-9 font-bold dark:drop-shadow-[0_4px_3px_rgba(255,255,255,0.2)] drop-shadow-[0_4px_3px_rgba(0,0,0,0.2)] ">{taskTitle}</h2>
            {promptSlides && promptSlides.map((slide: SlideProps, index) => (
                <div key={slide.id} className="text-white">
                  <Prompt 
                    rawContent={slide.prompt} 
                    correctAnswer={slide.correct_answer}
                    wasSubmitted={wasSubmitted}
                    isFakeLoadingSubmit={isFakeLoadingSubmit}
                    showCorrectAnswer={showCorrectAnswer}/>
                </div>
            ))}
        </div>
      
      {promptSlides && 
        <>
        <hr className={`fade-in mt-8 mb-8 ${wasSubmitted && "border-transparent"}`} />

        <div className={`grid fade-in items-center justify-center z-[200] pb-8
          ${!showSubmitBtn && 'fixed w-full rounded-none bottom-[22px] left-0 mb-0 pb-0'} `}>
          {showSubmitBtn &&
            <button className='form-submit-btn w-[70vw] max-w-[300px] p-2 text-lg border-2 border-white'
              onClick={() => handleSubmitAnimation()}>
              {!isFakeLoadingSubmit &&
                <span>
                  Submit {" "}
                  <SendIcon sx={{ ml: "5px", mb: "3px" }} />
                </span>}
              {isFakeLoadingSubmit &&
                <Loader setMarginTop={0} customClasses="!text-white !w-5 !h-5" />}
            </button>}

          {!showSubmitBtn &&
            <>
              <button
                className="form-sumbit-btn bg-neutral-600 text-white rounded-md py-3 mb-1"
                onClick={() => { setShowCorrectAnswer(!showCorrectAnswer); } }>
                {!showCorrectAnswer ? "Show " : "Hide "}
                Correct Answers
              </button>

              <button className='form-submit-btn see-final-grade-btn w-[100vw] lg:w-[60vw] rounded-none'
                onClick={() => handleSeeResults()}>
                See final grade{" "}
                <FeedIcon sx={{ ml: "5px", mb: "3px" }} />
              </button>
            </>}
        </div>

        </>
      }
    </div>
  );
}
