import {useState, useEffect} from 'react'
import { API_URL } from '../../../../../Constants'
import { useAppSelector } from '../../../../../store/store';

export default function SlideImage({img_url, customClasses}: {img_url: string, customClasses?: string}) {
    const [animating, setAnimating] = useState(false);
    const activeSlide = useAppSelector((state) => state.taskPlayer.activeSlideIndex)

    useEffect(() => {
        setAnimating(false)
        setTimeout(() => {
            setAnimating(true)
        }, 100);
    }, [])

  return (
    <img
      className={`self-start max-h-[320px] ${customClasses}`}
      src={API_URL + img_url}
      alt=""
    />
  )
}
