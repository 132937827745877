import { useState, useRef, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { useCreateSlide } from "../../api/task";
import { useMutation } from "react-query";
import { addSlide } from "../../store/features/taskCreateSlice";

export default function TaskCreateStep2() {
  const [prompt, setPrompt] = useState("");
  const [correctAnswer, setCorrectAnswer] = useState("");
  const [options, setOptions] = useState("")
  const [imgFile, setImgFile] = useState<File | null>(null);
  const [readingText, setReadingText] = useState("")
  const [timeStart, setTimeStart] = useState("")
  const [timeSpan, setTimeSpan] = useState("")
  const [isOption, setIsOption] = useState("")
  const [formError, setFormError] = useState<string | null>(null);
  const taskType = useAppSelector((state) => state.taskCreate.type)
  const taskFormat = useAppSelector((state) => state.taskCreate.format?.value)
  const slideImgField = useRef<any | null>();
  const task_id = useAppSelector((state) => state.taskCreate.id);
  
console.log(taskType);


  const dispatch = useAppDispatch();

  const { mutate: createSlide } = useMutation(useCreateSlide, {
    onSuccess: (data) => {
      dispatch(
        addSlide({
          prompt: data.prompt,
          correct_answer: data.correct_answer,
          img_url: data.img_url,
        })
      );
      setPrompt("");
      setCorrectAnswer("");
      setOptions("");
      setTimeStart("");
      setTimeSpan("");
      setImgFile(null);
      setReadingText("");
      slideImgField.current.value = null;
    },
  });

  const handleAddSlide = (e: React.FormEvent) => {
    e.preventDefault();
    createSlide({
      task_id,
      prompt,
      options,
      correct_answer: correctAnswer,
      img_file: imgFile,
      reading_text: readingText,
      time_start: timeStart,
      time_span: timeSpan,
      is_option: isOption
    });
  };

  const handleSlideImgChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormError(null);
    if (!e.target.files) return;
    const formFile = e.target.files[0];

    if (formFile.size <= 500000) {
      setImgFile(formFile);
    } else {
      setFormError("Image size must be below 500 KB");
      slideImgField.current.value = null;
    }
  };

  useEffect(() => {
    window.onbeforeunload = function () {
      return "Your work will be lost.";
    };
  }, []);

  return (
    <div className="grid grid-cols-12 pt-4 sm:pt-8 pb-20 h-full overflow-y-scroll">
      <div className="col-start-2 col-span-10 sm:col-start-3 sm:col-span-8">
        <h1 className="mb-4 text-lg font-bold tracking-tight text-gray-700 dark:text-white text-center">
          SLIDES
        </h1>
        <form onSubmit={handleAddSlide}>
        {/* {taskType == "speaking" && 
            <div className="mb-4">
            <textarea 
              cols={30} 
              rows={4}
              placeholder="Slide text..."
              onChange={(e) => setReadingText(e.target.value)}
              value={readingText}
              className="form-input"
              maxLength={250}
              required
              autoFocus
            />
          </div>
        } */}
        
          <div className="mb-4">
            <textarea
              placeholder="Prompt with gap (---)"
              id="prompt"
              onChange={(e) => setPrompt(e.target.value)}
              value={prompt}
              className="form-input"
              rows={5}
              autoFocus
            ></textarea>
          </div>
          
          <div className="mb-4">
            <input
              type="text"
              placeholder="Correct answer/s (one|two)"
              id="correctAnswer"
              onChange={(e) => setCorrectAnswer(e.target.value)}
              value={correctAnswer}
              className="form-input"
              maxLength={120}
            />
          </div>
          {(taskFormat == "reading_multiple_abcd" || 
            taskFormat == "listening_clips_abc" || 
            taskFormat == "listening_scrollable_abc") &&
            <div className="mb-4">
              <input
                type="text"
                placeholder="Clickable Options (one|two)"
                onChange={(e) => setOptions(e.target.value)}
                value={options}
                className="form-input"
                maxLength={500}
              />
            </div>
          }
          
          <div className="mb-4">
            <label htmlFor="slideImgFile" className="form-label">
              Slide image
            </label>
            <input
              type="file"
              id="slideImgFile"
              onChange={(e) => handleSlideImgChange(e)}
              ref={slideImgField}
              className="form-input"
            />
          </div>
          {(taskFormat == "reading_assign_option" || 
            taskFormat == "reading_missing_sentences") &&
            <div className="mb-4">
              <input
                type="number"
                placeholder="Is option? 0 | 1"
                onChange={(e) => setIsOption(e.target.value)}
                value={isOption}
                className="form-input"
              />
            </div>
          }
          {taskType == "listening" &&
            <div className="mb-4">
              <input
                type="number"
                placeholder="Starting time (in seconds)"
                onChange={(e) => setTimeStart(e.target.value)}
                value={timeStart}
                className="form-input"
              />
            </div>
          }
          {taskType == "listening" &&
            <div className="mb-4">
              <input
                type="number"
                placeholder="Time span (in seconds)"
                onChange={(e) => setTimeSpan(e.target.value)}
                value={timeSpan}
                className="form-input"
              />
            </div>
          }
          <button type="submit" className="form-submit-btn">
            Add slide
          </button>
          {formError && <div className="form-error">{formError}</div>}
        </form>
      </div>
    </div>
  );
}
