
export const checkAnswer = (correctGapAnswers: string, givenAnswer:string) => {
    let correctAnswersArr = correctGapAnswers.split('|');
    let isCorrect = false;
        
    for (let i = 0; i < correctAnswersArr.length; i++) {
        if( givenAnswer.toLocaleLowerCase() == correctAnswersArr[i].toLocaleLowerCase()){        
            isCorrect = true
        }
    }

    return isCorrect;
}