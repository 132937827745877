import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../../store/store";

export default function Prompt({rawContent, customClasses}: {rawContent:string, customClasses?:string }) {

  const typedAnswer = useAppSelector((state) => state.taskPlayer.typedAnswer)
  const isSlideDone = useAppSelector((state) => state.taskPlayer.isSlideDone)

  const [firstHalfContent, setfirstHalfContent] = useState("");
  const [secondHalfContent, setSecondHalfContent] = useState("");

  useEffect(() => {
    let index = rawContent.indexOf("---");
    let firstHalf = rawContent.substring(0, index);
    let secondHalf = rawContent.substring(index + 3);

    setfirstHalfContent(firstHalf);
    setSecondHalfContent(secondHalf);
  }, [rawContent, typedAnswer])


  const gapSpan = (
    <span
      className={`${
        isSlideDone ? "text-green-400 gap-span" : "text-primary"
      } underline font-bold`}
    >
      {typedAnswer.length ? typedAnswer : "________"}
    </span>
  );

  return (
    <p className={`tracking-tight text-[22px] mb-4 leading-8 
      ${customClasses}`}>
        {firstHalfContent}
        {gapSpan}
        {secondHalfContent}
    </p>
  );
}
