


export const numberMiddleware = (activeWord: string, typedAnswer:string) => {
    
    let typedAnswerArr = typedAnswer.split(" ");
    let typedAnswerAsNum = Number(typedAnswerArr[typedAnswer.length - 1]);

    if( typedAnswerAsNum >= 0 && typedAnswerAsNum <= 9){
        //@ts-ignore
        let matchingStr = numsAndStrs[typedAnswerAsNum]?.str;
        if(matchingStr){
            let fixedAnswer = typedAnswer.replace(String(typedAnswerAsNum), matchingStr);
            return fixedAnswer
        }
    }

    return false;
}

const numsAndStrs= {
    "1": {str: "one"},
    "2": {str: "two"},
    "3": {str: "three"},
    "4": {str: "four"},
    "5": {str: "five"},
    "6": {str: "six"},
    "7": {str: "seven"},
    "8": {str: "eight"},
    "9": {str: "nine"},
    "10": {str: "ten"},
}