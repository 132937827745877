import {useState, useEffect, useRef} from 'react'
import { SlideProps } from "../../../../../types/taskTypes";
import { useAppDispatch, useAppSelector } from "../../../../../store/store";
import Loader from "../../../../utils/Loader";
import { setActiveSlide, setIsAudioPlaying, setIsSlideDone, setTypedAnswer, setWrongAnswerSubmitted } from "../../../../../store/features/taskPlayerSlice";
import { API_URL } from '../../../../../Constants';

export default function SlideListeningClipsAbc({ data }: { data: SlideProps }) {

  const scrollableDivRef = useRef< HTMLDivElement| null>(null)

  const typedAnswer = useAppSelector((state) => state.taskPlayer.typedAnswer)
  const correctAnswer = data.correct_answer;
  const activeSlideIndex = useAppSelector((state) => state.taskPlayer.activeSlideIndex)
  const keepAudioPlaying = useAppSelector((state) => state.taskPlayer.activeTask?.keep_audio_playing)
  const [isDone, setIsDone] = useState(false)
  const dispatch = useAppDispatch();

  useEffect(() => {
    if(scrollableDivRef.current){
        scrollableDivRef.current.scrollTo(0,0);
      }
  }, [activeSlideIndex])
  

  useEffect(() => {
    setIsDone(false)
    if(typedAnswer == correctAnswer){
      dispatch(setIsSlideDone(true))
      setIsDone(true)
      setTimeout(() => {
        dispatch(setActiveSlide(activeSlideIndex+1))
      }, 1500);
    }else{
      if(typedAnswer.length){        
        let date = new Date();
        dispatch(setWrongAnswerSubmitted(date.toString()))
      }
    }
  }, [typedAnswer])

  useEffect(() => {  
    setIsDone(false)
    if(!keepAudioPlaying){
      dispatch(setIsAudioPlaying(false))
    }
    setTimeout(() => {
      if(activeSlideIndex > 0){
        dispatch(setIsAudioPlaying(true))
      }
    }, 100);
  }, [activeSlideIndex])
  
  const markAsClicked = (e: React.MouseEvent<HTMLElement>) => {
    //@ts-ignore
    e.target.classList.add("clicked");
  }

  const handleTypedAnswer = (option: string) => {
    dispatch(setTypedAnswer(option));
  }


  if (data === undefined) {
    return <Loader />;
  }

  return (
    <div ref={scrollableDivRef} className={`${isDone ? "fade-out" : "fade-in-animation"} grid justify-center px-2 max-h-[70vh] overflow-y-scroll`}>
      <p className="relative z-50 py-8 text-gray-700 dark:text-white text-[20px] mb-2">
        {data?.prompt}
      </p>

      <div className="grid grid-cols-2 gap-1">
        {/* <div className="absolute bottom-[10px] z-0 max-h-[170px] right-0 left-0 overflow-hidden flex justify-center items-center rounded-lg">
          <div className="overlay absolute bg-black opacity-50 w-full h-full"></div>
          <img src={API_URL + data.img_url} alt="" className="flex max-w-[90%]" />
        </div> */}
            {data.options?.split("|").map((option:string) => (
              <div key={option} className="col-span-2 flex mb-1">
                <button 
                  className="multiple-btn !text-lg mb-4 py-4 px-1"
                  onClick={(e) => {
                    markAsClicked(e);
                    handleTypedAnswer(option);
                  }}>
                  {option}
                </button>
              </div>
            ))}
      </div>
    </div>
  );
}
