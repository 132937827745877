import { useEffect, useRef, useState } from "react";
import MicIcon from "@mui/icons-material/Mic";
import { useAppDispatch, useAppSelector } from "../../../../../store/store";
import { setTypedAnswer } from "../../../../../store/features/taskPlayerSlice";


const SpeechRecognition =
  window.webkitSpeechRecognition || window.SpeechRecognition;
const recognition = new SpeechRecognition();
recognition.lang = "en-UK";
recognition.continuous = false;
recognition.interimResults = true;

export default function Transcriptor() {

  const microphoneRef = useRef< HTMLDivElement| null>(null);
  const [isListening, setIsListening] = useState(false);
  const activeSlideIndex = useAppSelector((state) => state.taskPlayer.activeSlideIndex)
  const activeTaskId = useAppSelector((state) => state.taskPlayer.activeTask?.id)
  const dispatch = useAppDispatch();
  const isTaskCompletedDataLoaded = useAppSelector((state) => state.taskPlayer.taskCompletedData);
  const isTaskCompleted = useAppSelector((state) => state.taskPlayer.isTaskCompleted)
  const totalSlides = useAppSelector((state) => state.taskPlayer.slides)


  useEffect(() => {
    dispatch(setTypedAnswer(""))
    if(activeSlideIndex > 0 && activeSlideIndex < totalSlides.length){
      handleListening();
    }
  }, [activeSlideIndex])

  useEffect(() => {
    if(recognition){
      recognition.stop();
    }
  }, [activeTaskId])
  

  recognition.onresult = (event) => {
    let transcript = event.results[0][0].transcript;
    handleSpokenAnswer(transcript);
  };

  recognition.onstart = () => {
    setIsListening(true);
    if(microphoneRef.current){
      microphoneRef.current.classList.add("listening");
      microphoneRef.current.classList.add("start-listening-animation");
        setTimeout(() => {
          microphoneRef.current!.classList.remove("start-listening-animation");
        }, 1000);
    }
  }

  recognition.onend = () => {
    setIsListening(false)
    if(microphoneRef.current){
      microphoneRef.current.classList.remove("listening");
    }
  };

  const handleListening = () => {    
      if(!isListening){
        recognition.start();
      }else{
        recognition.stop();
        setIsListening(false)
      }
  };

  const handleSpokenAnswer = (transcription: string) => {      
    dispatch(setTypedAnswer(transcription));
  };

  if (!recognition) {
    return (
      <div className="mircophone-container">
        Browser does not Support Speech Recognition.
      </div>
    );
  }

  console.log("isTaskCompleted:", isTaskCompleted);
  console.log("isTaskCompletedDataLoaded:", isTaskCompletedDataLoaded);
  
  
  
  if(isTaskCompleted || isTaskCompletedDataLoaded){
    return <></>
  }

  return (
    <div className="mircophone-container">
      <div
        className="microphone-icon-container"
        ref={microphoneRef}
        onClick={handleListening}
      >
        <MicIcon className="microphone-icon" />
      </div>
    </div>
  );
}