import { Dispatch, SetStateAction } from "react";

interface Props {
  cleanTextArray: Array<string>;
  typedAnswer: string;
  activeWordIndex: number;
  correctGapAnswers: string;
  setGapFillingAnswer: Dispatch<SetStateAction<string | null>>
}

export const checkSpeakingAnswer = ({cleanTextArray,typedAnswer,activeWordIndex, correctGapAnswers, setGapFillingAnswer}: Props) => {
  
  let transArr = typedAnswer.split(" ");
  let activeWordIterator = activeWordIndex;

  // if(cleanTextArray == undefined){return activeWordIterator}

  for (let wordIndex = 0; wordIndex < transArr.length; wordIndex++) {     
    
    let transcriptWord = transArr[wordIndex].toLocaleLowerCase().replace(/'/g, '').replace(/\./g,'').replace(/-/g,'');
    let activeWord = cleanTextArray[activeWordIterator]?.toLowerCase().replace(/-/g,'');   
    

    //If active word is the prompt's gap, iterate all the possible correct answers.
    if(activeWord == "promptgap"){
      let correctAnswersArr = correctGapAnswers.split('|');
      
      for (let i = 0; i < correctAnswersArr.length; i++) {
        if( transcriptWord == correctAnswersArr[i]){        
          setGapFillingAnswer(correctAnswersArr[i])
          activeWordIterator++
        }
      }
    }else{
      if(activeWord == transcriptWord){
        activeWordIterator++
      }else if(transArr.length >= 2 && wordIndex > 0){        
        let twoLastWordsTogether = transArr[wordIndex - 1].toLocaleLowerCase() + transArr[wordIndex].toLocaleLowerCase();
        if(activeWord == twoLastWordsTogether){
          activeWordIterator++
        }
      }
    }
  }

  return activeWordIterator;
};
