import {useEffect} from 'react'
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { setShowNavbar } from '../../../store/features/uiSlice';
import Step1 from './steps/Step1';
import Step2 from './steps/Step2';
import { LOGO_FILENAME } from '../../../Constants';
import Step3 from './steps/Step3';
import { Link } from 'react-router-dom';

export default function Register() {

    const dispatch = useAppDispatch();
    const activeRegisterStep = useAppSelector((state) => state.auth.register.active_step)

  return (
    <div className="grid pt-[60px] justify-center h-full w-full dark:text-white">
        <div className="box relative h-[300px] w-[320px] max-w-[90vw] rounded-lg border-[1px] border-neutral-300 dark:border-white text-center py-6 px-4">
          <img src={`../../img/${LOGO_FILENAME}`}
          className="absolute -top-4 -left-4 w-[70px] h-[70px] rounded-full" />

          {activeRegisterStep == 1 && <Step1 />}
          {activeRegisterStep == 2 && <Step2 />}
          {activeRegisterStep == 3 && <Step3 />}

        </div>
        <p className="check-result dark:text-white text-neutral-600 text-center text-lg rounded-md">
            Already have an account? 
            <Link to="/login"><span className="text-primary ml-2">Sign In</span></Link>
        </p>
    </div>
  )
}
