import { useState, useRef, useEffect } from "react";
import { useAppDispatch } from "../../store/store";
import { useNavigate } from "react-router-dom";
import { setStep1Data } from "../../store/features/taskCreateSlice";
import { getFormats, getTags, useCreateTask } from "../../api/task";
import { useMutation, useQuery } from "react-query";
import Loader from "../../components/utils/Loader";
import {capitalizeFirstLetter} from '../../services/stringCapitalizeFirstLetter'

export default function TaskCreateStep1() {
  const [title, setTitle] = useState("");
  const [level, setLevel] = useState("b1");
  const [type, setType] = useState("reading");
  const [formatId, setFormatId] = useState("")
  const [filteredFormatOptions, setFilteredFormatOptions] = useState([])
  const [points, setPoints] = useState("")
  const [skipChances, setSkipChances] = useState("")
  const [keepAudioPlaying, setKeepAudioPlaying] = useState("")
  const [imgFile, setImgFile] = useState<File | null>(null);
  const [audioFile, setAudioFile] = useState<File | null>(null);
  const imgField = useRef<any | null>();
  const audioField = useRef<any | null>();
  const [selectedTags, setSelectedTags] = useState<Array<string>>([]);
  const [formError, setFormError] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { data: tagOptions, refetch: refetchTags } = useQuery(["tags", level], () => getTags({level}));
  const { data: formatOptions } = useQuery(["formats"], getFormats);
  const { mutate: createTask } = useMutation(useCreateTask, {
    onSuccess: (data) => {
      dispatch(
        setStep1Data(data)
      );
    },
  });

  console.log(selectedTags.length);

  useEffect(() => {
    if(formatOptions){
      let options = formatOptions.filter((option: {value:string}) => {        
        return option.value.indexOf(type) >= 0
      })
      setFilteredFormatOptions(options);
      setFormatId(options[0].id)      
    }
  }, [type, formatOptions])
  
  useEffect(() => {
    refetchTags();
  }, [level])
  

  const handleSelectedTags = (tag_id: string) => {
    let isChecked = selectedTags.find((checkedTag) => {
      return checkedTag == tag_id
    });

    if(isChecked == undefined){
      setSelectedTags([...selectedTags, tag_id] ) 
    }else{
      let filtered = selectedTags.filter((checkedTag) => {
        return checkedTag !== tag_id
      });
      setSelectedTags(filtered);
    }
  }
  

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
      createTask({
        title,
        level,
        type,
        format_id: formatId,
        points,
        skip_chances: skipChances,
        tags_ids: selectedTags.join(","),
        thumbnail: imgFile,
        audio_file: audioFile,
        keep_audio_playing: keepAudioPlaying
      });
      navigate("/new-task-step-2");
  };

  const handleImgChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormError(null);
    if (!e.target.files) return;
    const formFile = e.target.files[0];

    if (formFile.size <= 500000) {
      setImgFile(formFile);
    } else {
      setFormError("Image size must be below 500 KB");
      imgField.current.value = null;
    }
  };

  const handleAudioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormError(null);
    if (!e.target.files) return;
    const formFile = e.target.files[0];

    if (formFile.size <= 20000000) {
      setAudioFile(formFile);
    } else {
      setFormError("Audio size must be below 20 MB");
      imgField.current.value = null;
    }
  };

  return (
    <div className="grid grid-cols-12 pt-4 sm:pt-8 pb-20 h-full">
      <div className="col-start-2 col-span-10 sm:col-start-3 sm:col-span-8">
        <h1 className="mb-4 text-lg font-bold tracking-tight text-gray-700 dark:text-white text-center">
          NEW TASK
        </h1>
        <form onSubmit={handleSubmit} >
          <div className="mb-4">
            <label className="form-label">Title</label>
            <input
              type="title"
              id="title"
              placeholder="..."
              onChange={(e) => setTitle(e.target.value)}
              value={title}
              className="form-input"
              maxLength={60}
              required
              autoFocus
            />
          </div>
          <div className="mb-4">
            <label className="form-label">Level</label>
            <select
              id="level"
              onChange={(e) => setLevel(e.target.value)}
              value={level}
              className="form-input"
              required
            >
              <option className="text-sm" value="b1">
                B1
              </option>
              <option className="text-sm" value="b2">
                B2
              </option>
            </select>
          </div>
          <div className="mb-4">
            <label className="form-label">Points</label>
            <input
              type="text"
              placeholder="20"
              onChange={(e) => setPoints(e.target.value)}
              value={points}
              className="form-input"
              maxLength={2}
            />
          </div>
          <div className="mb-4">
            <label className="form-label">Skip Chances</label>
            <input
              type="text"
              placeholder="null"
              onChange={(e) => setSkipChances(e.target.value)}
              value={skipChances}
              className="form-input"
              maxLength={2}
            />
          </div>
          <div className="mb-4">
            <label className="form-label">Type</label>
            <select
              id="type"
              onChange={(e) => setType(e.target.value)}
              value={type}
              className="form-input"
              required
            >
              <option className="text-sm" value="reading">
                Reading
              </option>
              <option className="text-sm" value="listening">
                Listening
              </option>
              <option className="text-sm" value="writing">
                Writing
              </option>
              <option className="text-sm" value="speaking">
                Speaking
              </option>
            </select>
          </div>
          <div className="mb-4">
            <label className="form-label">Format</label>
            <select
              id="format"
              onChange={(e) => setFormatId(e.target.value)}
              value={formatId}
              className="form-input"
              required
            >
              {!filteredFormatOptions && <Loader setMarginTop={0}/>}
              {filteredFormatOptions &&
                filteredFormatOptions.map((format: { id:string; value: string;}) => (
                  <option value={format.id} key={format.id}>
                    {format.value}
                  </option>
                ))
              }
            </select>
          </div>
          <div className="mb-4">
            <label className="form-label">Tags</label>
            <div className="checkbox-section">
              {!tagOptions && <Loader setMarginTop={0}/>}
              {tagOptions &&
                tagOptions.map((tag: { id:string; value: string; icon_ligature: string }) => (
                  <div key={tag.value} className="checkbox-wrapper">
                    <label htmlFor="" className="checkbox-label">
                     {capitalizeFirstLetter(tag.value)}
                    </label>
                    <input
                      className="form-checkbox-input"
                      onChange={(e) => handleSelectedTags(e.target.value)}
                      type="checkbox"
                      value={tag.id}
                    />
                  </div>
                ))}
            </div>
          </div>
          <div className="mb-8">
            <label htmlFor="image" className="form-label">
              Task thumbnail
            </label>
            <input
              type="file"
              id="imgFile"
              onChange={(e) => handleImgChange(e)}
              ref={imgField}
              className="form-input"
            />
          </div>
          {type == "listening" && 
          <>
            <div className="mb-4">
              <label className="form-label">Keep Audio Playing</label>
              <input
                type="text"
                placeholder="0 | 1"
                onChange={(e) => setKeepAudioPlaying(e.target.value)}
                value={keepAudioPlaying}
                className="form-input"
                maxLength={1} />
            </div>
            <div className="mb-8">
                <label htmlFor="audioFile" className="form-label">
                  Task Audio
                </label>
                <input
                  type="file"
                  id="audioFile"
                  onChange={(e) => handleAudioChange(e)}
                  ref={audioField}
                  className="form-input" />
              </div></>
          }          
          <button type="submit" className={`form-submit-btn ${selectedTags.length == 0 && '!text-neutral-500'}`} 
            disabled={selectedTags.length == 0}
            >
            Next step
          </button>
          {formError && <div className="form-error">{formError}</div>}
        </form>
      </div>
    </div>
  );
}
