import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { toggleDarkMode } from "../../store/features/uiSlice";
import NavbarBrand from "./NavbarBrand";
import NavbarGuest from "./NavbarGuest";
import NavbarAuth from "./auth/NavbarAuth";
import NavbarAuthMenu from "./auth/NavbarAuthMenu";

export default function Navbar() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const userData = useAppSelector((state) => state.auth.user);

  const handleThemeToggle = () => {
    dispatch(toggleDarkMode());
  };


  return (
    <nav className="navbar-nav">
      <div className="navbar-inner-wrapper">
        <NavbarBrand setIsOpen={setIsOpen} />
        {!userData && (
          <NavbarGuest
            setIsOpen={setIsOpen}
            handleThemeToggle={handleThemeToggle}
          />
        )}
        {userData && (
          <NavbarAuth
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        )}
      </div>
      {userData && (
        <NavbarAuthMenu
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handleThemeToggle={handleThemeToggle}
        />
      )}
    </nav>
  );
}
