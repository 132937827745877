import {useState, useEffect} from 'react'
import { useNavigate } from "react-router-dom";
import { API_URL, TIME_GAP_BETWEEN_ATTEMPTS } from "../../../Constants";
import { setFeedScrollPosition } from "../../../store/features/uiSlice";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import TaskType from "./TaskType";
import { formatDistanceToNowStrict,differenceInMinutes} from "date-fns";
import StarIcon from "@mui/icons-material/Star";
import { TaskProps } from "../../../types/taskTypes";
import TaskResultGrade from "./TaskResultGrade";
import { getGradeBgColor } from "../player/player_services/getGradeColor";
import TaskCardWait from './TaskCardWait';
import { LazyLoadImage  } from "react-lazy-load-image-component";
import Loader from '../../utils/Loader';


export default function TaskCard({data}: {data:TaskProps}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [mustWaitToDoAgain, setMustWaitToDoAgain] = useState(false)
  const [remainingTime, setRemainingTime] = useState(0)
  const [timeSinceLastDone, setTimeSinceLastDone] = useState<number | null>(null)
  const taskCompletedData = useAppSelector((state) => state.taskPlayer.taskCompletedData);

  // const imgFileNameIndex = data.thumbnail_url.indexOf("task_thumbnails") + String("task_thumbnails/").length + 1;
  const placeholderUrl = data.thumbnail_url.replace("task_thumbnails/", "task_thumbnails_placeholders/" ); 


  useEffect(() => {
    if(data.last_result !== undefined){
      let timeDiff = differenceInMinutes(new Date(), new Date(data.last_result.date));
      setTimeSinceLastDone(timeDiff)
    }
  }, [data, taskCompletedData])
  

  const redirectToExtended = async () => {
    
    if(timeSinceLastDone !== null){
      if(timeSinceLastDone < TIME_GAP_BETWEEN_ATTEMPTS){
        
        setRemainingTime(TIME_GAP_BETWEEN_ATTEMPTS - timeSinceLastDone)
        setMustWaitToDoAgain(true);
        setTimeout(() => {
          setMustWaitToDoAgain(false)
        }, 2000);

        return
      }
    }
    
    await dispatch(setFeedScrollPosition(window.pageYOffset));
    navigate(data.slug);
    
  };

  return (
    <div
      onClick={redirectToExtended}
      className="task-card fade-in-animation grid cursor-pointer mb-8 min-h-[240px] max-w-sm  rounded-lg overflow-hidden  border-[2px] dark:border-neutral-900/80 border-white shadow-lg dark:shadow-neutral-700/10 dark:bg-neutral-950 bg-white"
    >
      
      <div className="task-card-wrapper pb-2 relative">
        {data.last_result && !mustWaitToDoAgain && (
          <div className={`task-card-done-sash
          
          ${getGradeBgColor(data.last_result.result)}
            `}>
    
              <span className="text-sm mr-2">
                <TaskResultGrade result={data.last_result.result}/>
              </span>
              <span className="text-sm italic"> 
                {formatDistanceToNowStrict(Date.parse(data.last_result.date), {
                  addSuffix: true,
                })}
              </span>

          </div>
        )}

        <div className="relative flex h-[170px] justify-center overflow-hidden">

        <div className="task-card-type-icon absolute top-0 left-0 z-[110]">
              <TaskType type={data.type} />
          </div>
          
          <div className=" overflow-hidden w-full">
            <div className="overlay w-full h-full dark:bg-[rgba(0,0,0,0.1)] absolute top-0 left-0 z-[108]"></div>
            <TaskCardWait mustWaitToDoAgain={mustWaitToDoAgain} remainingTime={remainingTime}/>
            {!mustWaitToDoAgain && 
              <LazyLoadImage 
                className="zoom-in-and-out self-center w-full" 
                src={API_URL + data.thumbnail_url}
                placeholder={<span><Loader customClasses='!text-primary'/></span>}
                placeholderSrc={API_URL + placeholderUrl}
                alt={data.title}
              />
            }
          </div>

          <div className="absolute bottom-2 right-2 z-[109]">
            <div className="bg-white dark:bg-neutral-900 rounded-md py-1 px-2 text-neutral-600 dark:text-white ">
              <StarIcon sx={{ mr: "1px", mb: "2px", color: "gold"}} />
              <span className="text-sm">
                {data.points}{" "}
                {data.points > 1 ? "points" : "point"}
              </span>
            </div>
          </div>

        </div>
      </div>
      
      <div className="px-4 pb-2 text-neutral-700 dark:text-gray-300">
          <h5 className="tracking-tight text-lg mb-1 leading-6 ">{data.title}</h5>
          <p className="instructions dark:text-neutral-500 text-neutral-500 text-md pb-1">
            {data.format?.instructions}
          </p>
      </div>
    </div>
  );
}
