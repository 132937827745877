
const dev = "http://local-api.englishpractice.com";
const prod = "https://api.englishpractice.edu.uy";

export const CURRENT_ENV = process.env.NODE_ENV;
export const API_URL = process.env.NODE_ENV === 'development' ? dev : prod

//CONFIG
export const TIME_GAP_BETWEEN_ATTEMPTS = 10; //minutes
export const DEFAULT_THEME = "light"; //"dark" | "light"
export const TASKS_SHOWN_INITIALLY = 3;
export const EXTRA_TASKS_SHOWN_ON_LOAD_MORE = 3;
export const ACTIVITY_TASKS_DONE_SHOWN_INITIALLY =5;
export const ACTIVITY_EXTRA_TASKS_DONE_ON_LOAD_MORE = 5;


export const LOGO_FILENAME = "logo.png";