import {useState,useEffect} from 'react'
import { useQuery } from "react-query";
import TaskCard from "../../components/task/card/TaskCard";
import { getTasks } from "../../api/task";
import { useAppDispatch, useAppSelector } from '../../store/store';
import { EXTRA_TASKS_SHOWN_ON_LOAD_MORE, TASKS_SHOWN_INITIALLY } from '../../Constants';
import Loader from '../../components/utils/Loader';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import Icon from "@mui/material/Icon";
import { setIsSearchInputExtended, setNumberOfTasksDisplayed } from '../../store/features/feedSlice';
import { setShowNavbar } from '../../store/features/uiSlice';
import FiltersBar from './filters/FiltersBar';
import TaskType from '../../components/task/card/TaskType';
import TaskTag from '../../components/task/card/TaskTag';
import { capitalizeFirstLetter } from '../../services/stringCapitalizeFirstLetter';

export default function Feed() {
  const isUserLogged = localStorage.getItem("accessToken");

  const [waitingForToken, setWaitingForToken] = useState(true);
  const [isScrollSet, setisScrollSet] = useState(false)
  const numberOfTasksDisplayed = useAppSelector((state)=> state.feed.numberOfTasksDisplayed)
  const [tasksLeftToLoad, setTasksLeftToLoad] = useState(0)
  const [areTasksVisible, setAreTasksVisible] = useState(true)
  const [isFetchingMore, setIsFetchingMore] = useState(false)
  const [isFetchingNewSet, setIsFetchingNewSet] = useState(false)

  const scrollPosition = useAppSelector((state) => state.ui.feedScrollPosition);
  const taskCompletedData = useAppSelector((state) => state.taskPlayer.taskCompletedData);
  const userLevel = useAppSelector((state) => state.auth.user!.level)
  const activeTaskType = useAppSelector((state) => state.feed.activeTaskType)
  const activeTaskTag = useAppSelector((state) => state.feed.activeTaskTag)
  const dispatch = useAppDispatch();
  
  const { data: getTasksData, isLoading, refetch, isRefetching} = useQuery(
    ["tasks", {userLevel, activeTaskType, numberOfTasksDisplayed}], 
    () => getTasks({
      task_level: userLevel,
      task_type: activeTaskType,
      task_tag: activeTaskTag.value,
      number_of_tasks: numberOfTasksDisplayed
    }), 
    { enabled: false, keepPreviousData: true, 
      onSuccess: (resp) => {
        console.log("Tasks done fetching ----------------");
        
        setTasksLeftToLoad(resp.tasks_left)
        setAreTasksVisible(true)
        if(isFetchingMore){
          setTimeout(() => {
            window.scrollTo({left: 0, top: window.scrollY + 300, behavior:"smooth"})
            setIsFetchingMore(false)
          }, 100);
        }
        setIsFetchingNewSet(false)
      }}
  );

  let justMounted = false;
  useEffect(() => {
    justMounted = true;
    dispatch(setShowNavbar(true))
    dispatch(setIsSearchInputExtended(false))
  }, [])
  

  useEffect(() => {
    if(!justMounted){
      setAreTasksVisible(false)
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 500);
      
      setIsFetchingNewSet(true)
      refetch()
      dispatch(setNumberOfTasksDisplayed(TASKS_SHOWN_INITIALLY))
    }
  }, [userLevel, activeTaskType, activeTaskTag])
  
  useEffect(() => {
    refetch()
  }, [numberOfTasksDisplayed])

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, scrollPosition);
      setisScrollSet(true)
    }, 100);
  }, [scrollPosition])

  useEffect(() => {
    if(isUserLogged){      
      setWaitingForToken(false);
      refetch();
    }
  }, [isUserLogged, taskCompletedData])
  
  const handleLoadMoreTasks = () => {
    setIsFetchingMore(true)
    dispatch(setNumberOfTasksDisplayed(numberOfTasksDisplayed + EXTRA_TASKS_SHOWN_ON_LOAD_MORE))
  }

  if (isLoading || waitingForToken || isFetchingNewSet) {
    return (
      <div className="w-full text-center pt-10">
        <Loader  customClasses='!text-primary !w-12 !h-12' />
      </div>
    );
  }

  if(!getTasksData.tasks){
    return <div className="dark:text-white pt-5 text-center text-lg">You have not been assigned any tasks yet.</div>
  }

  if(getTasksData.tasks && getTasksData.tasks.length == 0 && !isLoading){
    return (
    <div className="text-white p-5 text-center text-lg bg-secondary mx-8 mt-8 rounded-lg">
      No results were found for: <br/> 
      <span className="uppercase">{userLevel}</span>  <br/>
      <span>
        <TaskType type={activeTaskType}/> {" "}
        {capitalizeFirstLetter(activeTaskType)} 
      </span> <br/>
      {<span>
        <Icon sx={{ fontSize: "18px", marginRight:"5px", position:"relative",top:"3px" }}>{activeTaskTag.icon_ligature}</Icon>
        {capitalizeFirstLetter(activeTaskTag.value)}
      </span>}
    </div>)
  }
  
    return (
      <>
      <div className={`
        ${!isScrollSet && "invisible"}
        ${areTasksVisible ? "fade-in" : "opacity-0"}
        `}>
        <div className="tasks grid bg-gray-100 dark:bg-darkThemeBg pt-6 px-6 justify-center pb-36">
          {getTasksData.tasks &&
            getTasksData.tasks.map((task: any) => (
              <TaskCard
                key={task.id}
                data={task} />
            ))}
          {tasksLeftToLoad > 0 &&
            <div className="px-8 pb-4 flex justify-center">
              <button
                onClick={() => { handleLoadMoreTasks(); } }
                className="form-submit-btn load-more-btn">
                {isFetchingMore && <Loader customClasses='!text-white !w-6 !h-6' />}
                {!isFetchingMore &&
                  <span>Load more <ExpandCircleDownIcon sx={{ mb: "2px" }} /></span>}
              </button>
            </div>}
        </div>
      </div>
    </>
    );
  
}
