import {useState, useEffect, useRef} from 'react'
import { SlideProps } from "../../../../../types/taskTypes";
import { useAppDispatch, useAppSelector } from "../../../../../store/store";
import Loader from "../../../../utils/Loader";
import { setActiveSlide, setIsAudioPlaying, setIsSlideDone, setTypedAnswer } from "../../../../../store/features/taskPlayerSlice";
import Prompt from '../_components/SlidePrompt';
import Skipper from '../_components/Skipper';
import { matchStringCase } from '../../player_services/matchStringCase';

export default function SlideListeningOneWord({ data }: { data: SlideProps }) {
  const typedAnswer = useAppSelector((state) => state.taskPlayer.typedAnswer)
  const correctAnswer = data.correct_answer;
  const activeSlideIndex = useAppSelector((state) => state.taskPlayer.activeSlideIndex)
  const isAudioPlaying = useAppSelector((state) => state.taskPlayer.isAudioPlaying)
  const [isDone, setIsDone] = useState(false)
  const dispatch = useAppDispatch();
  const answerInputRef = useRef(null);


  useEffect(() => {
    setIsDone(false)
    if(typedAnswer == correctAnswer){
      dispatch(setIsSlideDone(true))
      setIsDone(true)
      setTimeout(() => {
        dispatch(setActiveSlide(activeSlideIndex+1))
      }, 1500);
    }
  }, [typedAnswer])

  useEffect(() => {  
    setIsDone(false)
    setTimeout(() => {
      if(activeSlideIndex > 0){
        dispatch(setIsAudioPlaying(true))
      }
    }, 100);
  }, [activeSlideIndex])

  useEffect(() => {
    if(isAudioPlaying){
      //@ts-ignore
      answerInputRef.current.focus()
    }
  }, [isAudioPlaying, activeSlideIndex])
  
  const handleTypedAnswer = (e: React.ChangeEvent<HTMLInputElement>) => {
    let caseMatchingString = matchStringCase(correctAnswer, e.target.value);
    dispatch(setTypedAnswer(caseMatchingString));
  }

  if (data === undefined) {
    return <Loader />;
  }

  return (
    <div className={`${isDone ? "fade-out" : "fade-in-animation"} grid justify-center px-2 pt-2`}>
      <div className="grid text-center">
        <div className={`${isDone ? "fade-out" : "fade-in-animation"}`}>
          <div className="px-4 pb-2 pt-1 text-neutral-600 dark:text-white">
            <Prompt rawContent={data?.prompt} />
          </div>
          <div className="grid relative justify-center">
            <input
              type="text"
              className="form-input p-1 text-center text-[20px] max-w-[140px]"
              autoFocus
              onChange={(e) => handleTypedAnswer(e)}
              value={typedAnswer}
              ref={answerInputRef}
            />
            <Skipper customStyles='right-2 -bottom-2 left-auto !pt-0'/>
          </div>
      </div>
      </div>
    </div>
  );
}
