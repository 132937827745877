import axios from "axios";
import { LoginProps, LoginResponse, RegisterProps } from "../types/authTypes";
import { API_URL } from "../Constants";
import { getToken } from "./_getToken";

export const useLogin = async ({ host_url, email, password }: LoginProps) => {
  const response = await axios.post<LoginResponse>(
    API_URL + "/api/login",
    { host_url, email, password }
  );

  if (response.data?.access_token) {
    localStorage.setItem(
      "accessToken",
      JSON.stringify(response.data.access_token)
    );
    localStorage.setItem("userData", JSON.stringify(response.data.user));
  }

  return response;
};


export const useCheckAccessCode = async ({enteredCode}: {enteredCode:string}) => {
  const response = await axios.post(
    API_URL + "/api/check-access-code",
    {entered_code: enteredCode, host_url: window.location.host}
  );
  return response;
};


export const useRegister = async ({name, level, access_code}: RegisterProps) => {
  const response = await axios.post(
    API_URL + "/api/register",
    { name, 
      level, 
      host_url: window.location.host,
      access_code
    }
  );

  if (response.data?.access_token) {
    localStorage.setItem(
      "accessToken",
      JSON.stringify(response.data.access_token)
    );
    localStorage.setItem("userData", JSON.stringify(response.data.user));
  }

  return response;
};


export const createProfile = async ({email, password, password_confirmation}: 
  {email:string, password:string, password_confirmation:string}) => {
  let token = await getToken();

  let data = new FormData();
  if(email !== undefined){data.append('email', email)}
  if(password !== undefined){data.append('password', password)}
  if(password_confirmation !== undefined){data.append('password_confirmation', password_confirmation)}
  
  const response = await axios.post(
    API_URL + "/api/create-profile",
    data,
    {
      headers: {
        Accept :'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
}

export const useRegisterGuest = async () => {
  const response = await axios.post(
    API_URL + "/api/register-guest",
  );

  if (response.data?.access_token) {
    localStorage.setItem(
      "accessToken",
      JSON.stringify(response.data.access_token)
    );
    localStorage.setItem("userData", JSON.stringify(response.data.user));
  }

  return response;
};


export const useLogout = async () => {
  await localStorage.removeItem("accessToken");
  await localStorage.removeItem("userData");
};
