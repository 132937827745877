import { setRegisterActiveStep, setRegisterLevel } from '../../../../store/features/authSlice';
import { useAppDispatch } from '../../../../store/store';

export default function Step2() {

    const dispatch = useAppDispatch();

    const handleLevelChoice = (level:string) => {
        dispatch(setRegisterLevel(level))
        dispatch(setRegisterActiveStep(3))
    }

  return (
    <>
        <h1 className="text-2xl mb-6 pt-6 font-bricolage">Choose your level</h1>
        <div className="grid grid-cols-2 gap-4 px-2">
            <div className="col-span-1">
                <button type='submit'
                    onClick={() => {handleLevelChoice("b1")}}
                    className="form-submit-btn !bg-primary font-semibold h-[120px] w-full text-2xl">
                    B1
                </button>
            </div>
            <div className="col-span-1">
                <button type='submit'
                    onClick={() => {handleLevelChoice("b2")}}
                    className="form-submit-btn !bg-primary font-semibold h-[120px] w-full text-2xl">
                    B2
                </button>
            </div>
        </div>
    </>
  )
}
