import {useRef, useState} from 'react'
import { SlideProps } from '../../../../../../../../types/taskTypes'
import { API_URL } from '../../../../../../../../Constants'
import CheckIcon from '@mui/icons-material/Check';
import LazyImage from '../../../../_components/LazyImage';

interface Props{
  slide: SlideProps;
  wasSubmitted: boolean;
  isFakeLoadingSubmit: boolean;
}

export default function AssignOptionPerson({slide, wasSubmitted, isFakeLoadingSubmit}:Props) {

  const selectRef = useRef<HTMLSelectElement | null>(null);
  const [isCorrect, setIsCorrect] = useState(false)
  const [showCorrectAnswer, setShowCorrectAnswer] = useState(false)

  const checkIfCorrect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let value = e.target.value.toLowerCase();
    if(value.replace(/\s/g, '') == slide.correct_answer.toLowerCase().replace(/\s/g, '')){
      setIsCorrect(true)
    }else{
      setIsCorrect(false)
    }
  }

  return (
    <div className={`mb-4 dark:text-white grid grid-cols-12 border-2 border-primary rounded-md bg-neutral-50 dark:bg-neutral-950
    ${wasSubmitted && !isFakeLoadingSubmit && isCorrect && '!bg-green-950/30 border-2 !border-green-400/50'}
    ${wasSubmitted && !isFakeLoadingSubmit && !isCorrect && '!bg-red-950/30 border-2 !border-red-400/50'}`}>
      <div className="col-span-9 lg:col-span-10 grid items-center">
        <p className="p-2 -mt-1 lg:text-lg">{slide.prompt}</p>
      </div>
      <div className={`col-span-3 lg:col-span-2 grid pl-[2px] justify-center 
        ${!wasSubmitted && "bg-primary"}`}>
        <div className="max-h-[120px] overflow-hidden">
          {/* <img className="flex" src={API_URL + slide.img_url} alt="" /> */}
          <LazyImage imgSrc={slide.img_url}/>
        </div>
        <div className="">
          <select 
            ref={selectRef} 
            onChange={(e) => {checkIfCorrect(e)}}
            disabled={wasSubmitted}
            className={`w-full h-full text-2xl p-1 text-neutral-600 font-bold text-center
              ${isCorrect && "correctAnswerGiven"}
              ${!wasSubmitted && "bg-primary text-white"}`}>
              <option value="-">-</option>
              <option value="A">A</option>
              <option value="B">B</option>
              <option value="C">C</option>
              <option value="D">D</option>
              <option value="E">E</option>
              <option value="F">F</option>
              <option value="G">G</option>
              <option value="H">H</option>
          </select>
        </div>
      </div>
      {wasSubmitted && !isFakeLoadingSubmit && !isCorrect && (
      <div className="col-span-12 my-4 mx-2">
          <div className="correct-answer dark:bg-neutral-800 bg-neutral-200 rounded-md px-3 py-1 flex justify-center items-center">

            {showCorrectAnswer &&
              <>
                <span className="mr-1">
                  Correct Answer:{" "}
                </span>
                <span className="font-bold">{slide.correct_answer}</span>
              </>
            }

            {!showCorrectAnswer &&
              <button onClick={() => {setShowCorrectAnswer(true)}}>Show Correct Answer</button>
            }

          </div>
      </div>
        )}
    </div>
  )
}
