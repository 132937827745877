interface Props{
  mustWaitToDoAgain: boolean;
    remainingTime: number;
}

export default function TaskCardWait({mustWaitToDoAgain = false, remainingTime}: Props) {

  return (
    <div style={{transitionDuration: "1s"}} 
        className={`bg-primary text-white rounded-md p-1 absolute z-[200] w-full h-full grid items-center justify-center
        ${ mustWaitToDoAgain ? "opacity-1" : "opacity-0"}
    `}>
        <p className="text-center text-lg px-2">
        You must wait
            {" "}
            <span className="font-bold">{remainingTime}</span>
            {" "}
            {remainingTime > 1 ? 'minutes' : 'minute'}
            {" "}
        to do this task again
        </p>
    </div>
  )
}
