import { TaskDone } from '../../../../types/taskTypes'
import { capitalizeFirstLetter } from '../../../../services/stringCapitalizeFirstLetter'
import { formatDistanceToNow } from 'date-fns'
import TaskType from '../../../../components/task/card/TaskType'
import LazyImage from '../../../../components/task/player/slide/_components/LazyImage'
import { getGradeBgColor } from '../../../../components/task/player/player_services/getGradeColor'
import TaskResultGrade from '../../../../components/task/card/TaskResultGrade'
import Avatar from "@mui/material/Avatar";
import { API_URL } from '../../../../Constants'
import StarIcon from "@mui/icons-material/Star";


export default function StudentTaskDoneCard({td, showGrade = true}:{td:TaskDone, showGrade?:boolean}) {
  return (
    <div key={td.id} 
    className={`task-done-block lg:max-h-[120px] lg:mb-20 p-1 text-neutral-600 dark:text-neutral-200 mb-2 rounded-md`}>

      <div
        className={`task-done-card grid grid-cols-12 relative dark:bg-neutral-700 mb-2  rounded-md overflow-hidden `}>
        <div className="col-span-12">
          <h4 className='text-left mb-2'>
            {capitalizeFirstLetter( 
              formatDistanceToNow(
                  new Date(td.created_at), 
                  {addSuffix:true})
              )
            }
          </h4>
        </div>
        <div className={`col-span-2 relative grid min-h-[70px] overflow-hidden items-center justify-center
          ${!showGrade && "!col-span-3"}`}>
          <div className="overlay dark:bg-[rgba(0,0,0,0.3)] bg-[rgba(255,255,255,0.2)] absolute top-0 left-0 w-full h-full"></div>
          <div className="task-card-type-icon !z-50 !w-8 !h-8 absolute top-0 left-0 !pl-[3px] !pt-[6px]">
            <TaskType type={td.task.type} />
          </div>
          <LazyImage
            imgSrc={td.task.thumbnail_url}
            customClasses={`absolute top-0 rounded-md max-w-[300%] h-full lg:max-w-full justify-self-center 
              ${!td.user && 'max-w-[250%]'}`} />
        </div>

        <div className={`${!showGrade && "!col-span-9"} col-span-8 grid py-2 px-2 text-left items-center dark:bg-neutral-800`}>
          <div>
            <h4 className="text-sm mb-1">{td.task.title}</h4>
            <p className="text-sm dark:text-neutral-400 text-neutral-500 italic">{td.task_format?.instructions}</p>
          </div>
        </div>

        {showGrade && (
          <div className={`col-span-2  ${getGradeBgColor(td.result)}
            grid items-center justify-center`}>
            <TaskResultGrade
              result={td.result}
              customClasses="!h-[35px] !w-[35px] !flex items-center" />
          </div>
        )}
      </div>
    </div>
  )
}
