import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLogin } from "../../api/auth";
import { setUserData } from "../../store/features/authSlice";
import { useAppDispatch } from "../../store/store";
import Loader from "../../components/utils/Loader";

export default function Login() {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false)
  const login = useLogin;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true)
    const response = await login({ host_url: window.location.host, email, password });
    if (response.data.status == 1) {
      await dispatch(setUserData(response.data));
      navigate("/");
    } else {
      setError(response.data.msg);
    }
    setIsLoading(false)
  };

  return (
    <section>
      <div className="flex flex-col items-center justify-center px-6 pt-6 pb-2 mx-auto lg:py-0">
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-black dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Sign in to your account
            </h1>
            <form className="space-y-4 md:space-y-6" onSubmit={handleLogin}>
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="form-input"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  required
                  autoFocus
                />
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  className="form-input"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  required
                />
              </div>
              <button type="submit" className="form-submit-btn">
                {isLoading && <Loader setMarginTop={0} customClasses="!text-white !w-4 !h-4"/>}
                {!isLoading && <span>Sign in</span>}
              </button>
              {error && (
                <div className="form-error">
                  <p>{error}</p>
                </div>
              )}
              <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                <span className="mr-2">Don’t have an account yet?</span>
                <Link to="/register" className="text-gray-800 dark:text-white">
                  Sign up
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
