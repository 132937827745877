import { Dispatch, SetStateAction } from "react";
import Avatar from "@mui/material/Avatar";
// import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { API_URL } from "../../../Constants";
import { useAppSelector } from "../../../store/store";
import { useQuery } from "react-query";
// import { getNotifications } from "../../../api/notifications";
import NavbarAuthStars from "./NavbarAuthStars";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export default function NavbarAuth({ isOpen, setIsOpen }: Props) {
  const userData = useAppSelector((state) => state.auth.user);
  const userHasRing = Number(userData?.stars) >= 5 ? true : false;
  const userHas2Rings = Number(userData?.stars) >= 10 ? true : false;
  const isStudent = useAppSelector((state) => state.auth.user?.role) == "student";


  return (
    <>
      <ul className="navbar-list">
        <li className="flex items-center mr-2">
        {userData && isStudent &&
          <NavbarAuthStars />
        }
        </li>
        <li className="flex items-center">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="flex text-white items-center justify-center"
          >
            <span className="mr-2 sm:inline font-medium">
              {userData?.name.split(" ")[0]}
            </span>
            
            {userData?.avatar_file_name && 
            <div className="img-wrapper">
              <Avatar
                alt={userData?.name}
                src={`../../img/avatars/${userData.avatar_file_name}`}
                className={`navbar-avatar
                  ${userHasRing && 'has-ring'}
                  ${userHas2Rings && 'has-2-rings'}`}
                sx={{
                  display: "flex",
                  width: "33px",
                  height: "33px",
                  textAlign: "center",
                  alignItems: "center",
                }}
              />
            </div>
            }
            
            {!userData?.avatar_file_name && 
              <AccountCircleIcon className="!w-8 !h-8"               
              sx={{
                display: "flex",
                width: "30px",
                height: "30px",
                textAlign: "center",
                alignItems: "center",
                zIndex: 200
              }}/> 
            }
          </button>
        </li>
      </ul>
    </>
  );
}
