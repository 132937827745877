import {useState} from 'react'
import { SlideProps } from '../../../../../../../../types/taskTypes'
import { API_URL } from '../../../../../../../../Constants'

export default function SR_MS_Sentence({slide, letter}:{slide:SlideProps, letter:string}) {

  const [isCrossedOut, setIsCrossedOut] = useState(false)

  return (
    <div
      onClick={() => {setIsCrossedOut(!isCrossedOut)}} 
      className={`relative mb-4 dark:text-white grid grid-cols-12 border-2 border-primary rounded-md bg-neutral-50 dark:bg-neutral-950
      ${isCrossedOut && 'sentence-crossed-out'}`}>
      <div className="col-span-2 grid items-center justify-center">
        <div className="letter-box grid justify-center items-center font-bold bg-secondary text-white rounded-full w-9 h-9">
          <span className="flex text-2xl leading-5">{letter}</span>
        </div>
      </div>
      <div className="col-span-10 grid items-center min-h-[70px]">
        <p className="text-p lg:text-lg pt-1 -mt-1 dark:text-white">{slide.prompt}</p>
      </div>
    </div>
  )
}