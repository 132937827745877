import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../store/store'
import CancelIcon from '@mui/icons-material/Cancel';
import HomeIcon from "@mui/icons-material/Home";
import { TIME_GAP_BETWEEN_ATTEMPTS } from '../../../Constants';

export default function TaskFailed() {

  const user_performance = useAppSelector((state) => state.taskPlayer.user_performance)

  return (
    <div className="mt-10 px-4">
      <div className="task-completed-msg text-lg text-center bg-secondary dark:text-white rounded-md p-4 pb-8 mb-8">
        <div className="mb-4">
          <CancelIcon sx={{width:"90px", height:"90px"}}/>
        </div>
        <p className="mb-4">You didn't reach the minimum score: 
          <br/><span className="text-3xl font-bold">60%</span>
        </p>
        <p>Your score:</p>
        <p className="mb-4 text-3xl"><strong>{Math.round(user_performance)}%</strong></p>
        <span>You can try again in {TIME_GAP_BETWEEN_ATTEMPTS} minutes</span>
      </div>
      <div className="absolute left-0 bottom-2 w-full px-2">
        <button
              onClick={() => {window.history.back()}}
            className="bg-primary text-white w-full rounded-md py-4 flex items-center justify-center"
          >
            <HomeIcon sx={{ mr: 1 }} />
          </button>
        </div>
    </div>
  )
}
