export const matchStringCase = (correctString: string, toFixString: string) => {

  let correctStringArr = correctString.split('|');
  let firstCorrectAnswer = correctStringArr[0];

  if (toFixString.length) {
    let toFixStringArr = toFixString.split("");
    //Set every answer's letter case to match correct answer's case
    for (let index = 0; index < toFixStringArr.length; index++) {
      if (index < firstCorrectAnswer.length) {
        if (firstCorrectAnswer[index] === firstCorrectAnswer[index].toUpperCase()) {
          toFixStringArr[index] = toFixString[index].toUpperCase();
        } else {
          toFixStringArr[index] = toFixString[index].toLowerCase();
        }
      } else {
        toFixStringArr[index] = toFixString[index].toLowerCase();
      }
    }

    toFixString = toFixStringArr.join("");
    return toFixString;
  }else{
    return ""
  }
};
