import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../../../../store/store";
import {useDroppable} from '@dnd-kit/core';
import DraggableOption from "./DraggableOption";
import { StringToJsx } from "../../../_components/StringToJsx";

interface Props{
  rawContent:string,
  lastDrop: {
    droppable_target: string;
    draggable_content: string;
  } | null,
  index:number,
  correctAnswer: string,
  wasSubmitted: boolean,
  isFakeLoadingSubmit: boolean,
  showCorrectAnswer?: boolean
}

export default function DroppablePrompt({rawContent, lastDrop, index, correctAnswer, wasSubmitted, isFakeLoadingSubmit, showCorrectAnswer}: Props) {

  const thisDroppableId = 'droppable-' + index;
  const [content, setContent] = useState("")
  const [correctAnswerGiven, setCorrectAnswerGiven] = useState(false)
  const [finalAnswerGiven, setFinalAnswerGiven] = useState("")

  const {isOver, setNodeRef} = useDroppable({
    id: thisDroppableId,
  });

  const typedAnswer = useAppSelector((state) => state.taskPlayer.typedAnswer)

  const [firstHalfContent, setfirstHalfContent] = useState<string | null>(null);
  const [secondHalfContent, setSecondHalfContent] = useState<string | null>(null);

  useEffect(() => {
    if(!wasSubmitted){
      setCorrectAnswerGiven(content == correctAnswer)
    }
  }, [content])
  
  useEffect(() => {
    if(wasSubmitted){
    setFinalAnswerGiven(content);
    }
  }, [wasSubmitted])
  

  useEffect(() => {
    if(showCorrectAnswer == true){
      setTimeout(() => {
        setContent(correctAnswer)
      }, 200);
    }else{
      setContent(finalAnswerGiven)
    }
  }, [showCorrectAnswer])
  

  useEffect(() => {
    if(lastDrop){
      let targetContent = document.querySelector("#" + lastDrop.droppable_target + " .draggable-option");
      
      if(targetContent && lastDrop.draggable_content == content && lastDrop.droppable_target !== thisDroppableId){
        let exchangeContent = document.querySelector("#" + lastDrop.droppable_target + " .draggable-option");
        setContent(exchangeContent!.innerHTML);   
      }
      if(!targetContent && lastDrop.draggable_content == content && lastDrop.droppable_target !== thisDroppableId){
        setContent("")
      }
      if(lastDrop.droppable_target == thisDroppableId){
        setContent(lastDrop.draggable_content)
      }
    }
  }, [lastDrop])

  useEffect(() => {
    let gapIndex = rawContent.indexOf("---");

    let firstHalf = rawContent.substring(0, gapIndex);
    let secondHalf = rawContent.substring(gapIndex + 3);

    setfirstHalfContent(firstHalf);
    setSecondHalfContent(secondHalf);
  }, [rawContent, typedAnswer])




  const gapNode = () => {
    return(
      <div 
        ref={setNodeRef} id={thisDroppableId}
        className={`inline-block  rounded-md bg-neutral-200 duration-300
          ${content?.length == 0 && 'h-8 relative top-1  min-w-[80px]'}
          ${isOver && 'highlight-draggable-over'}
          ${correctAnswerGiven && 'correctAnswerGiven'}
          ${wasSubmitted && !isFakeLoadingSubmit && correctAnswerGiven && '!bg-green-500/50'}
          ${wasSubmitted && !isFakeLoadingSubmit && !correctAnswerGiven && '!bg-red-500/50'}
          `}
        >
        {content?.length > 0 &&
          <DraggableOption 
            option={content} 
            isAssigned={true} 
            correctAnswerGiven={correctAnswerGiven}
            wasSubmitted={wasSubmitted}
            isFakeLoadingSubmit={isFakeLoadingSubmit}
            showCorrectAnswer={showCorrectAnswer}
          />
        }
      </div>
    )
  };

  return (
      <>
          <StringToJsx string={firstHalfContent} /> 
          {gapNode()}
          <StringToJsx string={secondHalfContent} /> 
      </>
  );
}