import {useState, useEffect} from 'react'
import StarIcon from "@mui/icons-material/Star";
import HomeIcon from "@mui/icons-material/Home";
import Avatar from "@mui/material/Avatar";
import { useAppSelector } from "../../../../store/store";
import { API_URL } from "../../../../Constants";
import { Link } from 'react-router-dom';

export default function StarsToOneRingCutscene() {

  const userData = useAppSelector((state) => state.auth.user)
  const [convergeAnimation, setConvergeAnimation] = useState(false)
  const [highlightAnimation, setHighlightAnimation] = useState(false)
  const [avatarPulse, setAvatarPulse] = useState(false)
  const [showRing, setShowRing] = useState(false)
  const [show2ndRing, setShow2ndRing] = useState(false)

  let is2ndRing = Number(userData!.stars) == 10;

  useEffect(() => {
    if(is2ndRing){ setShowRing(true) }

    setTimeout(() => {
      setHighlightAnimation(true)
        setTimeout(() => {
          setHighlightAnimation(false)
          setConvergeAnimation(true)
          setTimeout(() => {
            setAvatarPulse(true)
            if(is2ndRing){
              setShow2ndRing(true)
            }else{
              setShowRing(true)
            }
          }, 2000);
        }, 1500);
    }, 1500);
  }, [])
  

  let starIconClasses = `cutscene-star 
    ${convergeAnimation && 'converge-animation'} 
    ${highlightAnimation && 'highlight-animation'}`;

  return (
    <div className="cutscene pt-8 bg-primary-dark min-h-full">
      <div className="stars-wrapper">
        <StarIcon className={starIconClasses}  />
        <StarIcon className={starIconClasses}  />
        <StarIcon className={starIconClasses}  />
        <StarIcon className={starIconClasses}  />
        <StarIcon className={starIconClasses}  />
      </div>
      <div className="avatar-wrapper flex justify-center pt-16">
        <Avatar
          alt={userData?.name}
          src={API_URL + userData?.avatar_file_name}
          className={`navbar-avatar 
            ${avatarPulse && 'avatar-pulse'}
            ${showRing && 'has-ring'}
            ${show2ndRing && 'has-2-rings'}
          `}
          sx={{
            display: "flex",
            width: "100px",
            height: "100px",
            textAlign: "center",
            alignItems: "center",
          }}
        />
      </div>
      {avatarPulse && 
      <div className="congrats-wrapper text-center pt-16 px-12">
        <p className="text-white text-2xl mb-4 drop-shadow-[0_4px_3px_rgba(0,0,0,0.5)] animate-pulse">Congratulations!</p>
        <p className="text-white text-lg mb-36 animate-pulse drop-shadow-[0_4px_3px_rgba(0,0,0,0.5)]">You've just obtained a {is2ndRing ? 'diamond' : 'golden'} ring!</p>
        <Link
        to={"/"}
        className="bg-[rgba(255,255,255,0.25)]  text-white rounded-md p-2 flex items-center justify-center"
      >
        <HomeIcon sx={{ mr: 1 }} />
      </Link>
      </div>
      }
    </div>
  );
}
