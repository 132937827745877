
export const checkSpelledWordMiddleware = (activeWord: string, typedAnswer:string) => {

    let typedAnswerArr = typedAnswer.split(" ");
    
    if(activeWord.toLowerCase() == typedAnswerArr.join("")){
        let fixedAnswer = typedAnswerArr.join("");
        return fixedAnswer
    }else{
        return false;
    }
}