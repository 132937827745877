import { useAppSelector } from "../../../../store/store";
import { preloadNextSlideImage } from "../player_services/preloadNextSlideImage";
import SR_OneWord from "./reading/SR_OneWord";
import SR_MultipleAbcd from "./reading/SR_MultipleAbcd";
import SR_AssignOption from "./reading/SR_AssignOption";
import SS_PictureDescription from "./speaking/SS_PictureDescription";
import SL_ClipsAbc from "./listening/SL_ClipsAbc";
import SL_OneWord from "./listening/SL_OneWord";
import SL_ScrollableAbc from "./listening/SL_ScrollableAbc";
import SL_ScrollableOneWord from "./listening/SL_ScrollableOneWord";
import SW_DragAndDropInGaps from "./writing/SW_dnd_in_gaps";
import SR_MissingSentences from "./reading/SR_MissingSentences";


export default function Slide() {
  const task_format = useAppSelector((state) => state.taskPlayer.activeTask?.format?.value)
  const slides = useAppSelector((state) => state.taskPlayer.slides)
  const activeSlideIndex = useAppSelector((state) => state.taskPlayer.activeSlideIndex)
  const activeSlide = slides[activeSlideIndex];
  const preloadNextImg = preloadNextSlideImage({slides, activeSlideIndex}) //Don't remove

  let suitableSlide;
  switch (task_format) {
    case "reading_multiple_abcd":
      suitableSlide = <SR_MultipleAbcd data={activeSlide} />
      break;
    case "reading_one_word":
      suitableSlide = <SR_OneWord data={activeSlide} />
      break;
    case "reading_assign_option":
      suitableSlide = <SR_AssignOption />
      break;
    case "speaking_picture_description": case "speaking_read_out_loud":
      suitableSlide = <SS_PictureDescription data={activeSlide} />
      break;
    case "listening_clips_abc":
      suitableSlide = <SL_ClipsAbc data={activeSlide} />
      break;
    case "listening_one_word":
      suitableSlide = <SL_OneWord data={activeSlide} />
      break;
    case "listening_scrollable_abc":
      suitableSlide = <SL_ScrollableAbc slides={slides} />
      break;
    case "listening_scrollable_one_word":
      suitableSlide = <SL_ScrollableOneWord slides={slides} />
      break;
    case "writing_dnd_in_gaps":
      suitableSlide = <SW_DragAndDropInGaps slides={slides} />
      break;
    case "reading_missing_sentences":
      suitableSlide = <SR_MissingSentences/>
      break;
    case "reading_word_transformation":
      suitableSlide = <SR_OneWord data={activeSlide} />
      break;
  }


  return (
    <div className="mb-4 bg-white dark:bg-black md:px-4">
      {suitableSlide}
    </div>
  );
}
