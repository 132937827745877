import { Routes, Route, Navigate } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import Page404 from "./pages/Page404";
import { useAppSelector } from "./store/store";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/register/Register";
import Settings from "./pages/settings/Settings";
import TaskPlayer from "./pages/task/TaskPlayer";
import TaskCreateStep1 from "./pages/task/TaskCreateStep1";
import TaskCreateStep2 from "./pages/task/TaskCreateStep2";
import TabsBar from "./components/tabsbar/TabsBar";
import Feed from "./pages/feed/Feed";
import Activity from "./pages/activity/Activity";
import Scores from "./pages/scores/Scores";
import Dashboard from "./pages/admin/Dashboard";
import Media from "./pages/media/Media";
import CreateProfile from "./pages/auth/CreateProfile";


function App() {

  const activeTheme = useAppSelector((state) => state.ui.themePref);
  const showNavbar = useAppSelector((state)=> state.ui.showNavbar);
  const isLogged = useAppSelector((state) => state.auth.user?.id);
  const role = useAppSelector((state) => state.auth.user?.role)

  const isAdmin = role == "admin";
  const isTeacher = role == "teacher";

  if (activeTheme === 'dark') {
    document.body.classList.add('bg-darkThemeBg', 'dark');
  } else {
    document.body.classList.remove('bg-darkThemeBg', 'dark')
  }

  return (
    <div className={`${activeTheme == "dark" ? "bg-darkThemeBg dark" : "bg-white"} h-full`}>
      <div className={`app ${showNavbar && "pt-[52px]"} h-full`}>
        {showNavbar && <Navbar />}
        <Routes>
          <Route path="/" element={isLogged ?<Feed /> : <Navigate to={"/register"}/>} />
          <Route path="/media" element={isLogged ?<Media /> : <Navigate to={"/login"}/>} />
          <Route path="/activity" element={isLogged ?<Activity /> : <Navigate to={"/login"}/>} />
          <Route path="/scores" element={isLogged ?<Scores /> : <Navigate to={"/login"}/>} />

          <Route path="/:slug" element={isLogged ?<TaskPlayer /> : <Navigate to={"/login"}/>} />

          <Route path="/login" element={ !isLogged ? <Login /> : <Navigate to={"/"}/>} />
          <Route path="/register" element={ !isLogged ? <Register /> : <Navigate to={"/"}/>} />
          <Route path="/settings" element={isLogged ?<Settings /> : <Navigate to={"/login"}/>} />
          <Route path="/create-profile" element={isLogged ?<CreateProfile /> : <Navigate to={"/register"}/>} />

          <Route path="/new-task" element={isAdmin ?<TaskCreateStep1 /> : <Navigate to={"/login"}/>} />
          <Route path="/new-task-step-2" element={isAdmin ?<TaskCreateStep2 /> : <Navigate to={"/login"}/>} />
          <Route path="/dashboard" element={(isAdmin || isTeacher) ?<Dashboard /> : <Navigate to={"/login"}/>} />

          <Route path="*" element={<Page404 />} />
        </Routes>
      </div>
      {showNavbar && <TabsBar />}
    </div>
  );
}

export default App;
