import {useState, useEffect, useRef} from 'react'
import { SlideProps } from "../../../../../../../types/taskTypes";
import { useAppDispatch, useAppSelector } from "../../../../../../../store/store";
import Loader from "../../../../../../utils/Loader";
import SendIcon from '@mui/icons-material/Send';
import FeedIcon from '@mui/icons-material/Feed';
import { setTaskCompletedData, setUserPerformance } from '../../../../../../../store/features/taskPlayerSlice';
import { useRegisterTaskDone } from '../../../../../../../api/task';
import { useMutation } from 'react-query';
import AssignOptionOption from './items/AssignOptionOption';
import AssignOptionPerson from './items/AssignOptionPerson';
// import ScrollableOneWordPrompt from './components/ScrollableOneWordPrompt';

interface Props{
    activeTab:string, 
    setOptionsTabScrollPosition: (position:number) => void,
    optionsTabScrollPosition: number
}

export default function OptionsTab({activeTab, setOptionsTabScrollPosition, optionsTabScrollPosition}:Props) {

const slides = useAppSelector((state) =>state.taskPlayer.slides)
const optionsSlides = slides.filter((slide) => {
    return slide.is_option
})
  const [wasSubmitted, setWasSubmitted] = useState(false)
  const [doneWasRegistered, setDoneWasRegistered] = useState(false)
  const [isFakeLoadingSubmit, setIsFakeLoadingSubmit] = useState(false)
  const [showSubmitBtn, setShowSubmitBtn] = useState(true)
  const [fadeOutTransition, setFadeOutTransition] = useState(false)
  const task_id = useAppSelector((state) => state.taskPlayer.activeTask?.id);
  const dispatch = useAppDispatch();
  const { mutate: registerTaskDone, isLoading: taskCompletedLoading } = useMutation(useRegisterTaskDone);
  const [tempTaskCompletedData, setTempTaskCompletedData] = useState(null)
  const scrollableDiv = useRef<HTMLDivElement | null>(null)
  const letters = ["A","B","C","D","E","F","G","H"];

  useEffect(() => {
    scrollableDiv.current!.scrollTop = optionsTabScrollPosition;
  }, [activeTab])

  useEffect(() => {
    if(wasSubmitted){
        setTimeout(() => {
            let correctAnswers = document.getElementsByClassName("correctAnswerGiven");
            let wrongAnswers = slides.length - correctAnswers.length;
            let wrongPercentage = wrongAnswers * 100/ slides.length;
            let user_performance = 100 - wrongPercentage;
            
            dispatch(setUserPerformance(user_performance))      

            if(!doneWasRegistered){
                setTimeout(() => {
                    registerTaskDone({task_id, user_performance}, { onSuccess: (data) => {      
                        setTempTaskCompletedData(data);
                        setDoneWasRegistered(true)
                    }});
                }, 500);
            }
        }, 1000);
    }
  }, [wasSubmitted])


  const handleSubmitAnimation = () => {
    setWasSubmitted(true)
    setIsFakeLoadingSubmit(true);

    setTimeout(() => {
        setShowSubmitBtn(false)
        setIsFakeLoadingSubmit(false)
        scrollableDiv.current!.scrollTop = 0;
    }, 2000);
  }

  const handleSeeResults = () => {
    setFadeOutTransition(true)
    setTimeout(() => {
        // dispatch(setIsTaskCompleted(true))
        dispatch(setTaskCompletedData(tempTaskCompletedData));
    }, 1500);
  }

  if (slides === undefined) {
    return <Loader />;
  }

  return (
    <div
        onScroll={() => {setOptionsTabScrollPosition(scrollableDiv.current!.scrollTop)}} 
        ref={scrollableDiv} 
        className={`overflow-y-scroll  max-h-[calc(100vh-70px)]`}>
        <div className={`w-full`}>
            {optionsSlides && optionsSlides.map((slide: SlideProps, index) => (
                <div key={slide.id}>
                    <AssignOptionOption slide={slide} letter={letters[index]}/>
                </div>
            ))}
        </div>
    </div>
  );
}
