import { useRef, useEffect, useState } from "react";
import { API_URL } from "../../../../../Constants";
import { PreloadMedia, MediaType } from 'react-preload-media';
import { useAppSelector } from "../../../../../store/store";

const correctAnswerAudioUrl = API_URL + '/files/audio_ui/sound_eff_correct_answer.mp3';
const wrongAnswerAudioUrl = API_URL + '/files/audio_ui/sound_eff_wrong_answer.mp3';
const taskCompletedAudioUrl = API_URL + '/files/audio_ui/sound_eff_task_completed.mp3';
const taskFailedAudioUrl = API_URL + '/files/audio_ui/sound_eff_task_failed.mp3';


const media = [
  { type: MediaType.Audio, url: correctAnswerAudioUrl},
  { type: MediaType.Audio, url: wrongAnswerAudioUrl},
];

export default function UIAudioPlayer() {
    const correctAnswerPlayer = useRef(null);
    const wrongAnswerPlayer = useRef(null);
    const taskCompletedPlayer = useRef(null);
    const taskFailedPlayer = useRef(null);
    
    const answerIsCorrect = useAppSelector((state) => state.taskPlayer.isSlideDone)
    const wrongAnswerSubmitted = useAppSelector((state) => state.taskPlayer.wrongAnswerSubmitted)
    const typedAnswer = useAppSelector((state) => state.taskPlayer.typedAnswer)
    const taskCompletedData = useAppSelector((state) => state.taskPlayer.taskCompletedData)
    const result = useAppSelector((state) => state.taskPlayer.user_performance);

  useEffect(() => {
    if(taskCompletedData){
        if(result >= 60){
          //@ts-ignore
          taskCompletedPlayer.current.play();
        }else{
          //@ts-ignore
          taskFailedPlayer.current.play();
        }
    }
  }, [taskCompletedData])
  

  useEffect(() => {
    if(answerIsCorrect && !taskCompletedData){
        //@ts-ignore
        correctAnswerPlayer.current.play();
    }
  }, [answerIsCorrect])

  useEffect(() => {
    if(typedAnswer.length){
      //@ts-ignore
      wrongAnswerPlayer.current.play();

      if (navigator.vibrate) {
        // vibration API supported
          navigator.vibrate(500);
      }
    }
  }, [wrongAnswerSubmitted])
  
  
  

  return (
    <>
        <PreloadMedia media={media} onFinished={() => console.log("Audio Preloading DONE")}>
        </PreloadMedia>
        <audio ref={correctAnswerPlayer} autoPlay={false}>
            <source src={correctAnswerAudioUrl} type="audio/mpeg"  />
        </audio>
        <audio ref={wrongAnswerPlayer} autoPlay={false}>
            <source src={wrongAnswerAudioUrl} type="audio/mpeg"  />
        </audio>
        <audio ref={taskCompletedPlayer} autoPlay={false}>
            <source src={taskCompletedAudioUrl} type="audio/mpeg"  />
        </audio>
        <audio ref={taskFailedPlayer} autoPlay={false}>
            <source src={taskFailedAudioUrl} type="audio/mpeg"  />
        </audio>
    </>
  )
}
