import { useEffect, useState, useRef } from "react";
import { useAppSelector } from "../../../../../../../../store/store";
import { StringToJsx } from "../../../../_components/StringToJsx";


export default function SR_MS_Prompt({rawContent, wasSubmitted, isFakeLoadingSubmit, correctAnswer, showCorrectAnswer}: {rawContent:string, wasSubmitted: boolean, isFakeLoadingSubmit: boolean, correctAnswer:string, showCorrectAnswer: boolean }) {

  const selectRef = useRef<HTMLSelectElement | null>(null);
  const [isCorrect, setIsCorrect] = useState(false)
  const [finalAnswerGiven, setFinalAnswerGiven] = useState("")
  const typedAnswer = useAppSelector((state) => state.taskPlayer.typedAnswer)
  const isSlideDone = useAppSelector((state) => state.taskPlayer.isSlideDone)

  const [firstHalfContent, setfirstHalfContent] = useState("");
  const [secondHalfContent, setSecondHalfContent] = useState("");

  useEffect(() => {
    let index = rawContent.indexOf("---");
    let firstHalf = rawContent.substring(0, index);
    let secondHalf = rawContent.substring(index + 3);

    setfirstHalfContent(firstHalf);
    setSecondHalfContent(secondHalf);
  }, [rawContent, typedAnswer])

  useEffect(() => {
    if(wasSubmitted){
    setFinalAnswerGiven(selectRef.current!.value);
    }
  }, [wasSubmitted])

  useEffect(() => {
    if(showCorrectAnswer == true){
      setTimeout(() => {
        selectRef.current!.value = correctAnswer;
      }, 200);
    }else{
      selectRef.current!.value = finalAnswerGiven;
    }
  }, [showCorrectAnswer])
  

  const checkIfCorrect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let value = e.target.value.toLowerCase();
    if(value.replace(/\s/g, '') == correctAnswer.toLowerCase().replace(/\s/g, '')){
      setIsCorrect(true)
    }else{
      setIsCorrect(false)
    }
  }

  const gapSpan = (
    <select 
      ref={selectRef} 
      onChange={(e) => {checkIfCorrect(e)}}
      disabled={wasSubmitted}
      className={`w-16 text-2xl p-1 rounded-md !text-white font-bold text-center
        ${isCorrect && "correctAnswerGiven"}
        ${!wasSubmitted && "bg-primary "}
        ${!showCorrectAnswer && wasSubmitted && !isFakeLoadingSubmit && isCorrect && '!bg-green-800'}
        ${!showCorrectAnswer && wasSubmitted && !isFakeLoadingSubmit && !isCorrect && '!bg-red-800'}
        ${showCorrectAnswer && '!bg-primary'}
        `}>
        <option value="-">-</option>
        <option value="A">A</option>
        <option value="B">B</option>
        <option value="C">C</option>
        <option value="D">D</option>
        <option value="E">E</option>
        <option value="F">F</option>
        <option value="G">G</option>
        <option value="H">H</option>
    </select>
  );

  return (
    <p className={`tracking-tight text-[22px] text-neutral-800 dark:text-white text-justify mb-4 leading-8`}>
        <StringToJsx string={firstHalfContent} />
        {gapSpan}
        <StringToJsx string={secondHalfContent} />
    </p>
  );
}
