import {useState} from 'react'
import { SlideProps } from '../../../../../../../../types/taskTypes'
import { API_URL } from '../../../../../../../../Constants'

export default function AssignOptionOption({slide, letter}:{slide:SlideProps, letter:string}) {

  const optionTitle = slide.prompt.split("|")[0];
  const optionContent = slide.prompt.split("|")[1]

  const [isCrossedOut, setIsCrossedOut] = useState(false)

  return (
    <div
      onClick={() => {setIsCrossedOut(!isCrossedOut)}} 
      className={`relative mb-4 dark:text-white grid grid-cols-12 border-2 border-primary rounded-md bg-neutral-50 dark:bg-neutral-950
      ${isCrossedOut && 'option-crossed-out'}`}>
      <div className="col-span-12">
        <div className="letter-box absolute left-2 top-2 flex justify-center items-center font-bold bg-secondary text-white rounded-full w-9 h-9">
          <span className="text-2xl leading-5">{letter}</span>
        </div>
        <h3 className={`sr-ao-option-heading text-center text-2xl py-3 mb-0
        ${"letter-"+ letter}`}>
          {optionTitle}
        </h3>
        <p className="text-p lg:text-lg p-4 pt-1 -mt-1 dark:text-white">{optionContent}</p>
      </div>
    </div>
  )
}
