import {useState, useEffect, useRef} from 'react'
import {DndContext} from '@dnd-kit/core';
import {DragEndEvent} from '@dnd-kit/core/dist/types'
import {useDroppable} from '@dnd-kit/core';
import { SlideProps } from "../../../../../types/taskTypes";
import { useAppDispatch, useAppSelector } from "../../../../../store/store";
import Loader from "../../../../utils/Loader";
import SendIcon from '@mui/icons-material/Send';
import FeedIcon from '@mui/icons-material/Feed';
import { setIsStepperVisible, setTaskCompletedData, setUserPerformance } from '../../../../../store/features/taskPlayerSlice';
import { useRegisterTaskDone } from '../../../../../api/task';
import { useMutation } from 'react-query';
import { shuffleArray } from '../../player_services/shuffleArray';
import DroppablePrompt from './components/SW_dnd_in_gaps/DroppablePrompt';
import DraggableOption from './components/SW_dnd_in_gaps/DraggableOption';
import { API_URL } from '../../../../../Constants';

export default function SlideWritingDragAndDropInGaps({ slides }: { slides: SlideProps[] }) {

  const paddingTop = 180; //Makes dragging on the first gap easier since the user can scroll the options wrapper closer
  const [originalSetOfOptions, setOriginalSetOfOptions] = useState<Array<string>>([])
  const [draggableOptions, setDraggableOptions] = useState<Array<string>>([])
  const [optionsWereProcessed, setOptionsWereProcessed] = useState(false)
  const [wasSubmitted, setWasSubmitted] = useState(false)
  const [doneWasRegistered, setDoneWasRegistered] = useState(false)
  const [isFakeLoadingSubmit, setIsFakeLoadingSubmit] = useState(false)
  const [showSubmitBtn, setShowSubmitBtn] = useState(true)
  const [fadeOutTransition, setFadeOutTransition] = useState(false)
  const [tempTaskCompletedData, setTempTaskCompletedData] = useState(null)
  const [isOptionBeingDragged, setIsOptionBeingDragged] = useState("")
  const [showCorrectAnswer, setShowCorrectAnswer] = useState(false)


  interface DropType{
    droppable_target: string;
    draggable_content: string;
  }
  const [lastDrop, setLastDrop] = useState< DropType | null>(null)
  const {isOver, setNodeRef} = useDroppable({
    id: 'droppable-options',
  });
  const style = {
    background: isOver ? 'green !important' : undefined,
  };


  const task_id = useAppSelector((state) => state.taskPlayer.activeTask?.id);
  const taskTitle = useAppSelector((state) => state.taskPlayer.activeTask?.title)
  const taskThumbnail = useAppSelector((state) => state.taskPlayer.activeTask?.thumbnail_url)
  const dispatch = useAppDispatch();

  const scrollableDiv = useRef<HTMLDivElement | null>(null)

  const { mutate: registerTaskDone, isLoading: taskCompletedLoading } = useMutation(useRegisterTaskDone);


  useEffect(() => {
    if(lastDrop){
      if(lastDrop.droppable_target == "droppable-options"){
       setDraggableOptions(draggableOptions => [...draggableOptions, lastDrop.draggable_content])
      }
    }
  }, [lastDrop])
  

    useEffect(() => {
        dispatch(setIsStepperVisible(false))
        let optionsArr = []
        for (let slideIndex = 0; slideIndex < slides.length; slideIndex++) {
          const draggableOption = slides[slideIndex].correct_answer;
          if(draggableOption){optionsArr.push(draggableOption)}

          if(slideIndex == slides.length -1){
            setOriginalSetOfOptions(optionsArr)
            setDraggableOptions(shuffleArray(optionsArr))
            setTimeout(() => {
              if(scrollableDiv.current){
                scrollableDiv.current.scrollTop = paddingTop;  
              }
              setOptionsWereProcessed(true)
            }, 200);
          }
        }        
    }, [])

  useEffect(() => {
    if(wasSubmitted){
        setTimeout(() => {
            let correctAnswers = document.getElementsByClassName("correctAnswerGiven");
            let wrongAnswers = originalSetOfOptions.length - correctAnswers.length;
            let wrongPercentage = wrongAnswers * 100/ originalSetOfOptions.length;
            let user_performance = 100 - wrongPercentage;
            
            dispatch(setUserPerformance(user_performance))      

            if(!doneWasRegistered){
                setTimeout(() => {
                    registerTaskDone({task_id, user_performance}, { onSuccess: (data) => {      
                        setTempTaskCompletedData(data);
                        setDoneWasRegistered(true)
                    }});
                }, 500);
            }
        }, 1000);
    }
  }, [wasSubmitted])


  const handleSubmitAnimation = () => {
    setWasSubmitted(true)
    setIsFakeLoadingSubmit(true);

    setTimeout(() => {
        setShowSubmitBtn(false)
        setIsFakeLoadingSubmit(false)
        if(scrollableDiv.current){
          scrollableDiv.current.scrollTop = paddingTop
        };
    }, 2000);
  }

  const handleSeeResults = () => {
    setFadeOutTransition(true)
    setTimeout(() => {
        // dispatch(setIsTaskCompleted(true))
        dispatch(setTaskCompletedData(tempTaskCompletedData));
    }, 1500);
  }

  if (slides === undefined) {
    return <Loader />;
  }

  function handleDragEnd(event: DragEndEvent) {
    //@ts-ignore
    let optionContent = event.active.data.current.content;
    setIsOptionBeingDragged("")

    if(event.over){
      if (event.over.id !== null) {
        let targetPromptContent = document.querySelector("#" + event.over.id + " .option-assigned")
        
        if(targetPromptContent !== null){
          setTimeout(() => {
            let displayedOptions = document.getElementsByClassName("draggable-option");           
            let missingOption = "";

            //Iterate all options
            for (let x = 0; x < originalSetOfOptions.length; x++) {
              let optionWasFound = false;
              for (let y = 0; y < displayedOptions.length; y++) {
                if(originalSetOfOptions[x] == displayedOptions[y].innerHTML){
                  optionWasFound = true;
                }
              }
              if(!optionWasFound){
                missingOption = originalSetOfOptions[x];
              }
            }
            if(missingOption.length > 0){
              setDraggableOptions(draggableOptions => [...draggableOptions, missingOption])
            }
          }, 500);
        }
      }
      

      if (event.over.id !== null) {
        setLastDrop({
          draggable_content: optionContent,
          droppable_target: event.over.id as string
        })
        setDraggableOptions(draggableOptions.filter((option) => option !== optionContent))
      }
      if(event.over.id == null){
        setDraggableOptions(draggableOptions => [...draggableOptions, optionContent])
      }


    }
  }

  return (
    <DndContext 
      onDragEnd={handleDragEnd} 
      onDragStart={(e)=>{setIsOptionBeingDragged(e.active.data.current!.content)}}>

    {/* PROMPT */}
      <div ref={scrollableDiv} 
          className={`max-w-[720px] h-[70vh] pb-[10vh] pt-44 px-3
              ${optionsWereProcessed ? "fade-in" : "opacity-0"}
              ${fadeOutTransition && "fade-out"}
              ${!isOptionBeingDragged ? 'overflow-y-scroll': 'overflow-hidden !h-[95vh] lg:pr-8'}
              ${draggableOptions.length == 0 && 'overflow-y-scroll !h-[100vh]'}
          `}>
            <h2 className="dark:text-white text-neutral-800 text-center font-serif text-2xl my-9 font-bold dark:drop-shadow-[0_4px_3px_rgba(255,255,255,0.2)] drop-shadow-[0_4px_3px_rgba(0,0,0,0.2)] ">{taskTitle}</h2>
            <hr className="my-8 dark:opacity-30"/>
          <div className={`slides-wrapper dark:text-white text-2xl
              ${isFakeLoadingSubmit ? 'fade-out' : 'fade-in'}`}>
              {slides && slides.map((slide, index) => (
                  <span key={slide.id}>
                      <DroppablePrompt
                          index={index}
                          rawContent={slide.prompt}
                          lastDrop={lastDrop}
                          correctAnswer={slide.correct_answer}
                          wasSubmitted={wasSubmitted}
                          isFakeLoadingSubmit={isFakeLoadingSubmit}
                          showCorrectAnswer={showCorrectAnswer}
                          />
                  </span>
              ))}
        </div>
        
        <br/><br/>
        <hr className="mb-8 dark:opacity-30"/>
        <br/><br/>

        {optionsWereProcessed &&
        <div className="img-wrapper mb-4">
          <img src={API_URL + taskThumbnail} alt="" className="rounded-md"/>
        </div>
        }

    {/* SUBMIT BTN / SEE RESULTS BTN */}
        {optionsWereProcessed && draggableOptions.length == 0 && 
          <div className={`w-full grid items-center justify-center z-[200]
            fixed bottom-0 left-0 dark:bg-neutral-900 border-t-2 border-neutral-500 bg-white h-[90px] mb-0 
            ${!showSubmitBtn && "!h-[150px] py-2"}`}>

            {showSubmitBtn &&
                <button className='form-submit-btn w-[70vw] max-w-[300px] p-2 text-lg border-2 border-white'
                    onClick={() => handleSubmitAnimation()}>
                        {!isFakeLoadingSubmit && 
                        <span>
                            Submit {" "}
                            <SendIcon sx={{ml:"5px", mb:"3px"}}/>
                        </span>
                        }
                        {isFakeLoadingSubmit && 
                        <Loader setMarginTop={0} customClasses="!text-white !w-5 !h-5"/>}
                </button>
            }

            {!showSubmitBtn &&
            <>
              <button 
                className="form-sumbit-btn bg-neutral-600 text-white rounded-md py-3 mb-1"
                onClick={() => { setShowCorrectAnswer(!showCorrectAnswer); } }>
                  {!showCorrectAnswer ? "Show " : "Hide "}
                  Correct Answers
                </button>
              <button className='form-submit-btn see-final-grade-btn w-[70vw]'
                onClick={() => handleSeeResults()}>
                  See final grade{" "}
                  <FeedIcon sx={{ ml: "5px", mb: "3px" }} />
                </button>
            </>
            }
          </div>
        }
      </div>

    {/* OPTIONS */}
    <div ref={setNodeRef} style={style} className={`p-4 dark:bg-neutral-900 bg-neutral-200 h-[30vh] border-t-2 border-neutral-500/20 overflow-visible w-full absolute bottom-0 duration-500 text-center
          ${fadeOutTransition && "fade-out"}
          ${(isOptionBeingDragged) && "!bg-transparent !border-transparent"}
          ${draggableOptions.length == 0 && "hidden"}
          `}>
            {draggableOptions && draggableOptions.map((option:string) => (
              <div key={option} className="inline-block mb-2 mr-2 fade-in">
                <DraggableOption 
                  option={option}
                  isOptionBeingDragged={isOptionBeingDragged}
                  />
              </div>
            ))}
          </div>

    </DndContext>
  );
}
