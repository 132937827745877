import * as React from 'react';
import {useState} from 'react'
import { useAppDispatch } from '../../../../../store/store';
import { setIsStepperVisible } from '../../../../../store/features/taskPlayerSlice';
import SentencesTab from './components/missing_sentences/SentencesTab';
import PromptTab from './components/missing_sentences/PromptTab';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  

export default function SlideReadingMissingSentences() {

    const dispatch = useAppDispatch();
    const [activeTab, setActiveTab] = useState('prompt')
    const [promptTabScrollPosition, setPromptTabScrollPosition] = useState(0)
    const [optionsTabScrollPosition, setOptionsTabScrollPosition] = useState(0)

    React.useEffect(() => {
        dispatch(setIsStepperVisible(false))
    }, [])


    const handleChange = (newActiveTab: string) => {
        setActiveTab(newActiveTab)
    };
    
    return (
        <div className="sr-assign-option-tabs">
            <div className="tabs-bar">
                <div className="tabs-wrapper">

                    <button 
                      onClick={() => {handleChange("prompt")}}
                      className={`${activeTab == "prompt" && "active"}`}>
                        TEXT
                    </button>

                    <button 
                      onClick={() => {handleChange("sentences")}}
                      className={`${activeTab == "sentences" && "active"}`}>
                        SENTENCES
                    </button>

                </div>
            </div>
            
              <div className={`tab-content
                ${activeTab == "prompt" && "tab-visible"}`}>
                  <PromptTab 
                      activeTab={activeTab}
                      setPromptTabScrollPosition={setPromptTabScrollPosition}
                      promptTabScrollPosition={promptTabScrollPosition}
                  />
              </div>
            
             
              <div className={`tab-content
                ${activeTab == "sentences" && "tab-visible"}`}>
                <SentencesTab 
                    activeTab={activeTab}
                    setOptionsTabScrollPosition={setOptionsTabScrollPosition}
                    optionsTabScrollPosition={optionsTabScrollPosition}
                />
              </div>
            
            
        </div>
    );
}
