import {useState, useEffect} from 'react'
import {useDraggable} from '@dnd-kit/core';

interface Props{
    option:string;
    isOptionBeingDragged?: string;
    isAssigned?: boolean,
    correctAnswerGiven?: boolean,
    wasSubmitted?: boolean,
    isFakeLoadingSubmit?: boolean,
    showCorrectAnswer?:boolean;
}

export default function DraggableOption({option, isOptionBeingDragged, isAssigned = false, correctAnswerGiven, wasSubmitted, isFakeLoadingSubmit, showCorrectAnswer}:Props) {  

  const [isHidden, setIsHidden] = useState(false)
  
  const {attributes, listeners, setNodeRef, transform} = useDraggable({
    id: 'draggable-'+ option,
    data: {
      content: option,
    },
    disabled: wasSubmitted
  });


  useEffect(() => {
    setIsHidden(false)
    if(isOptionBeingDragged){
      if(isOptionBeingDragged.length > 0 && isOptionBeingDragged !== option){
        setIsHidden(true)
      }
    }
  }, [isOptionBeingDragged])

  const style = transform ? {
    transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
  } : undefined;

  return (
    <div ref={setNodeRef} style={style} {...listeners} {...attributes}
      className={`draggable-option text-white bg-primary text-2xl rounded-md p-2 touch-none
        ${isHidden && 'opacity-0'}
        ${isAssigned && 'option-assigned'}
        ${!showCorrectAnswer && wasSubmitted && !isFakeLoadingSubmit && correctAnswerGiven && '!bg-green-800'}
        ${!showCorrectAnswer && wasSubmitted && !isFakeLoadingSubmit && !correctAnswerGiven && '!bg-red-800'}
        ${showCorrectAnswer && " !bg-green-800/80"}
        `}
        >
      {option}
  </div>
  )
}
