import axios from "axios";
import { API_URL } from "../Constants";
import { getToken } from "./_getToken";


export const getAllStudentsByLevel = async ({level}: {level:string}) => {
    let token = await getToken();

    let data = new FormData();
    if(level !== undefined){data.append('level', level)}
    
    const response = await axios.post(
      API_URL + "/api/get-all-students-by-level",
      data,
      {
        headers: {
          Accept :'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }

  export const getTasksDoneByLevel = async ({level, hide_failed_ones, number_of_tasks}: 
    {level:string, hide_failed_ones?: "1" | undefined, number_of_tasks:number}) => {
    let token = await getToken();

    let data = new FormData();
    if(level !== undefined){data.append('level', level)}
    if(hide_failed_ones !== undefined){data.append('hide_failed_ones', hide_failed_ones)}
    if(number_of_tasks !== undefined){data.append('number_of_tasks', number_of_tasks.toString())}
    
    const response = await axios.post(
      API_URL + "/api/get-tasks-done-by-level",
      data,
      {
        headers: {
          Accept :'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }


  export const getGlobalRankingPoints = async ({level}: {level:string}) => {
    let token = await getToken();

    let data = new FormData();
    if(level !== undefined){data.append('level', level)}
    
    const response = await axios.post(
      API_URL + "/api/get-global-ranking-points",
      data,
      {
        headers: {
          Accept :'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }