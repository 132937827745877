
export const calculateGrade = (result: number) => {
    let grade;

    if(result >= 95){
        grade = "A+"
    }else if(result >= 85){
        grade = "A"
    }else if(result >= 80){
        grade = "A-"
    }else if(result >= 75){
        grade = "B+"
    }else if(result >= 70){
        grade = "B"
    }else if(result >= 65){
        grade = "C+"
    }else if(result >= 60){
        grade = "C"
    }else if(result >= 55){
        grade = "C-"
    }else if(result >= 50){
        grade = "D"
    }else if(result >= 35){
        grade = "E"
    }else{
        grade = "F"
    }

    return grade;
}